import { appsApi } from 'apis';
import errorString from '../../utils/errorString';

export const appManageListActionTypes = {
  GET_APP_LIST: 'appManagementAction/GET_APP_LIST',
  GET_APP_LIST_SUCCESS: 'appManagementAction/GET_APP_LIST_SUCCESS',
  GET_APP_LIST_FAILURE: 'appManagementAction/GET_APP_LIST_FAILURE',
  GET_APP_BY_ID:'appManagementAction/GET_APP_BY_ID',
  GET_APP_BY_ID_SUCCESS:'appManagementAction/GET_APP_BY_ID_SUCCESS',
  GET_APP_BY_ID_FAILURE:'appManagementAction/GET_APP_BY_ID_FAILURE',
  DELETE_APP: 'appManagementAction/DELETE_APP',
  DELETE_APP_SUCCESS: 'appManagementAction/DELETE_APP_SUCCESS',
  DELETE_APP_FAILURE: 'appManagementAction/DELETE_APP_FAILURE',
  // DELETE_APP_RESET: 'DELETE_APP_RESET',
  CREATE_APP: 'appManagementAction/CREATE_APP',
  CREATE_APP_SUCCESS: 'appManagementAction/CREATE_APP_SUCCESS',
  CREATE_APP_FAILURE: 'appManagementAction/CREATE_APP_FAILURE',
  // CREATE_APP_RESET: 'CREATE_APP_RESET',
  UPDATE_APP: 'appManagementAction/UPDATE_APP',
  UPDATE_APP_SUCCESS: 'appManagementAction/UPDATE_APP_SUCCESS',
  UPDATE_APP_FAILURE: 'appManagementAction/UPDATE_APP_FAILURE',
  SET_CURRENT_APP_SUCCESS: 'appManagementAction/SET_APP_CURRENT_APP_SUCCESS',
  SET_CURRENT_APP_FAILURE: 'appManagementAction/SET_APP_CURRENT_APP_FAILURE',
  RESET_STATUS_CURRENT_APP_FAILURE: 'appManagementAction/RESET_STATUS_APP_CURRENT_APP_FAILURE',

  RESET_MANAGE_APP: 'appManagementAction/RESET_MANAGE_APP',
}

//* thunk action
const getAppsList = () => async dispatch => {
  //* notify begin action to component
  dispatch(getAppsListBegin());
  try {
    const appsList = await appsApi.getAppsList();
    dispatch(getAppsListSuccess(appsList));
  } catch (error) {
    dispatch(getAppsListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getAppById = (id) => async dispatch => {
  //* notify begin action to component
  dispatch(getAppByIdBegin());
  try {
    const app = await appsApi.getAppById(id);
    dispatch(getAppByIdSuccess(app));
  } catch (error) {
    dispatch(getAppByIdFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const deleteAppsList = (appId) => async dispatch => {
  dispatch(deleteAppsBegin());
  try {
    const app = await appsApi.deleteApp(appId);
    dispatch(deleteAppsSuccess(app));
  } catch (error) {
    dispatch(deleteAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const createApps = (values) => async dispatch => {
  dispatch(createAppsBegin());
  try{
    const app = await appsApi.createApp(values);
    dispatch(createAppsSuccess(app));
  } catch (error) {
    if (typeof error.response.data.message === 'string') {
      dispatch(createAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    } else {
      dispatch(createAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.isUrl))));
    }
  }
}

const updateApps = (values) => async dispatch => {
  dispatch(updateAppsBegin());
  try{
    const app = await appsApi.updateApp(values);
    dispatch(updateAppsSuccess(app));
  } catch (error) {
    if (typeof error.response.data.message === 'string') {
      dispatch(updateAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    } else {
      dispatch(updateAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.isUrl))));
    }
  }
}

//* only pure action
const getAppsListBegin = () => {
  return {
    type: appManageListActionTypes.GET_APP_LIST
  }
}

const getAppsListSuccess = (appsList) => {
  return {
    type: appManageListActionTypes.GET_APP_LIST_SUCCESS,
    payload: {
      appsList: appsList
    },
  }
}

const getAppsListFailure = (error) => {
  return {
    type: appManageListActionTypes.GET_APP_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

//* only pure action 
const deleteAppsBegin = () => {
  return {
    type: appManageListActionTypes.DELETE_APP
  }
}

const deleteAppsSuccess = (app) => {
  return {
    type: appManageListActionTypes.DELETE_APP_SUCCESS,
    payload: {
      app: app
    },
  }
}

const deleteAppsFailure = (error) => {
  return {
    type: appManageListActionTypes.DELETE_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

//* only pure action 
const createAppsBegin = () => {
  return {
    type: appManageListActionTypes.CREATE_APP
  }
}

const createAppsSuccess = (app) => {
  return {
    type: appManageListActionTypes.CREATE_APP_SUCCESS,
    payload: {
      app: app
    },
  }
}

const createAppsFailure = (error) => {
  return {
    type: appManageListActionTypes.CREATE_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

//* only pure action 
const updateAppsBegin = () => {
  return {
    type: appManageListActionTypes.UPDATE_APP
  }
}

const updateAppsSuccess = (app) => {
  return {
    type: appManageListActionTypes.UPDATE_APP_SUCCESS,
    payload: {
      app: app
    },
  }
}

const updateAppsFailure = (error) => {
  return {
    type: appManageListActionTypes.UPDATE_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

//* get app
const getCurrentApp = (id) => async dispatch => {
  try{
    const currentAppResponse = await appsApi.getCurrentApp(id);

    const currentApp = {
      ...currentAppResponse.data
    }

    dispatch(setCurrentAppSuccess(currentApp));
  } catch (error) {
    dispatch(setCurrentAppFailure(error));
  }
}

const setCurrentAppSuccess = (currentApp) => {
  return {
    type: appManageListActionTypes.SET_CURRENT_APP_SUCCESS,
    payload: {
      currentApp: currentApp
    }
  }
}
//* only pure action 
const getAppByIdBegin = () => {
  return {
    type: appManageListActionTypes.GET_APP_BY_ID
  }
}

const getAppByIdSuccess = (app) => {
  return {
    type: appManageListActionTypes.GET_APP_BY_ID_SUCCESS,
    payload: {
      app: app
    },
  }
}

const setCurrentAppFailure = (error) => {
  return {
    type: appManageListActionTypes.SET_CURRENT_APP_FAILURE,
    payload: {
      error: error
    },
  }
}
const getAppByIdFailure = (error) => {
  return {
    type: appManageListActionTypes.GET_APP_BY_ID_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetStatusCurrentApp = (error) => {
  return {
    type: appManageListActionTypes.RESET_STATUS_CURRENT_APP_FAILURE,
  }
}

const resetManageApp = () => {
  return {
    type: appManageListActionTypes.RESET_MANAGE_APP,
  }
}

export default {
  getAppsList,
  deleteAppsList,
  createApps,
  updateApps,
  getCurrentApp,
  resetStatusCurrentApp,
  getAppById,
  resetManageApp,
}
