import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContentText,
  DialogContent
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'; 
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    height: '40px',
    width: '40px',
    margin: 'auto',
    display: 'block',
    color: theme.palette.error.main,
  },
  dialog: {
    //backgroundColor: theme.palette.error.main,
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold'
  }
}));

const DialogDelete = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // eslint-disable-next-line
  const { open, className, title, message, handleClose, handleConfirm, ...rest } = props;

  return (
    <div
      className={className}
      {...rest}
    >
      <Dialog
        aria-labelledby="responsive-dialog-title"
        className={classes.dialog}
        fullScreen={fullScreen}
        onClose={handleClose}
        open={open}
      >
        <DeleteIcon className={classes.icon} />

        <DialogTitle
          
          id="responsive-dialog-title"
        >
          <div className={classes.title}>
            {title}
          </div>
        </DialogTitle>

        <DialogContent hidden={message ? false : true}>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
          >
            No !!!
          </Button>

          <Button 
            autoFocus
            color="primary"
            onClick={handleConfirm}
            variant="contained"
          >
            Yes, delete it
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogDelete.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default DialogDelete;
