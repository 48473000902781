import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

const history = createBrowserHistory();
const store = configureStore();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let returnUrl = urlParams.get('return_url');
let isLogOutChildApp = urlParams.get('is_log_out');

//* use check for logout automation when logout child app
if(isLogOutChildApp){
  sessionStorage.setItem('isLogOutChildApp', isLogOutChildApp);
}

//* use check for redirect to child app when login from child app
if (returnUrl) {
  sessionStorage.setItem('returnUrl', returnUrl);
}

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
