import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink
      to="/#"
      {...props}
    />
  </div>
));

export default CustomRouterLink;
