import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import {
  NavBar,
  TopBar
  //ChatBar
} from './components';
import { sessionAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.user);
  const error = useSelector(state => state.session.error);

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    //* isLogOut is passed from logout child app
    const isLogOut = sessionStorage.getItem('isLogOutChildApp');
    if (isLogOut) {
      //* logout automation
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('isLogOutChildApp')
      return;
    }

    const token = localStorage.getItem('accessToken');
    const returnUrl = sessionStorage.getItem('returnUrl');

    //* if logged in automation => redirect to child app
    if (returnUrl && token) {
      window.location.href = returnUrl+'?accessToken='+token;
      return;
    }
    // eslint-disable-next-line
  }, [route]);

  useEffect(() => {

    const sessionReset = async () => {
      await dispatch(sessionAction.sessionReset());
    }

    if(error ==='ErrorCode: 401!  undefined'){
      sessionReset();
      router.history.push('/auth/login');
      return;
    }
    dispatch(sessionAction.getUserInfo());
    // eslint-disable-next-line
  }, [error]);

  return user && user.id !== '' ? (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  ) : null ;
};

Dashboard.propTypes = {
  route: PropTypes.object
};
export default Dashboard;
