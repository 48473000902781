import { appRoleManageListActionTypes } from '../actions';

const initialState = {
  appRoleList: [],
  appPermissionList: []
};

const roleManageListReducer = (state = initialState, action) => {
  switch (action.type) {
    //* get app role list
      case appRoleManageListActionTypes.GET_ROLE_LIST: {
        return {
          getAppRoleListStatus: 'waiting',
        };
      }

      case appRoleManageListActionTypes.GET_ROLE_LIST_SUCCESS: {
        return {
          ...state,
          appRoleList: action.payload.appRoleList,
          getAppRoleListStatus: 'success'
        };
      }

      case appRoleManageListActionTypes.GET_ROLE_LIST_FAILURE: {
        return {
          ...state,
          error: action.payload.error,
          appRoleList: [],
          getAppRoleListStatus: 'error'
        }
      }
    //* get app permission list
      case appRoleManageListActionTypes.GET_PERMISSION_LIST_SUCCESS: {
        return {
          ...state,
          appPermissionList: action.payload.appPermissionList,
          getAppPermissionListStatus: 'success'
        };
      }

      case appRoleManageListActionTypes.GET_PERMISSION_LIST_FAILURE: {
        return {
          ...state,
          getAppPermissionError: action.payload.error,
          getAppPermissionListStatus: 'error'
        }
      }


    //* post create app role
      case appRoleManageListActionTypes.CREATE_ROLE_SUCCESS: {
        return {
          ...state,
          appRoleCreateInfor: action.payload.appRoleCreateInfor,
          createAppRoleStatus: 'success'
        };
      }

      case appRoleManageListActionTypes.CREATE_ROLE_FAILURE: {
        return {
          ...state,
          createAppRoleError: action.payload.error,
          createAppRoleStatus: 'error'
        }
      }
    //* reset create app role
      case appRoleManageListActionTypes.RESET_CREATE_ROLE: {
        return {
          ...state,
          createAppRoleStatus: undefined,
          createAppRoleError: undefined,
          appRoleCreate: undefined,
          appPermissionList: [],
        }
      }


    //* get app role where id
      case appRoleManageListActionTypes.GET_UPDATE_ROLE_SUCCESS: {
        return {
          ...state,
          appRoleGetUpdate: action.payload.appRoleGetUpdate,
          getUpdateAppRoleStatus: 'success'
        };
      }

      case appRoleManageListActionTypes.GET_UPDATE_ROLE_FAILURE: {
        return {
          ...state,
          getUpdateAppRoleError: action.payload.error,
          getUpdateAppRoleStatus: 'error'
        }
      }
    //* post app role update
      case appRoleManageListActionTypes.POST_UPDATE_ROLE_SUCCESS: {
        return {
          ...state,
          postUpdateAppRoleStatus: 'success'
        };
      }

      case appRoleManageListActionTypes.POST_UPDATE_ROLE_FAILURE: {
        return {
          ...state,
          postUpdateAppRoleError: action.payload.error,
          postUpdateAppRoleStatus: 'error'
        }
      }
    //* reset app update role
      case appRoleManageListActionTypes.RESET_UPDATE_ROLE: {
        return {
          ...state,
          getUpdateAppRoleStatus: undefined,
          postUpdateAppRoleStatus: undefined,
          postUpdateAppRoleError: undefined,
          appRoleGetUpdate: undefined,
          appPermissionList: []
        }
      }


    //* delete app role where id
      case appRoleManageListActionTypes.DELETE_ROLE_SUCCESS: {
        return {
          ...state,
          appRoleDeleteId: action.payload.appRoleDeleteId,
          deleteAppRoleStatus: 'success',
          deleteAppRoleError: undefined
        };
      }

      case appRoleManageListActionTypes.DELETE_ROLE_FAILURE: {
        return {
          ...state,
          deleteAppRoleError: action.payload.error,
          deleteAppRoleStatus: 'error'
        }
      }

      case appRoleManageListActionTypes.DELETE_ROLE_STATUS_RESET: {
        return {
          ...state,
          deleteAppRoleStatus: undefined,
          deleteAppRoleError: undefined
        }
      }


    //* reset app role status
      case appRoleManageListActionTypes.ROLE_STATUS_RESET: {
        return initialState
      }


    //* return default
      default: {
        return state;
      }
  }
};

export default roleManageListReducer;
