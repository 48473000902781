import { myAppsApi } from 'apis';
import errorString from '../../utils/errorString';

export const myAppsActionTypes = {
  GET_MY_APPS: 'myAppsAction/GET_MY_APPS',
  GET_MY_APPS_SUCCESS: 'myAppsAction/GET_MY_APPS_SUCCESS',
  GET_MY_APPS_FAILURE: 'myAppsAction/GET_MY_APPS_FAILURE',
  GET_MY_APPS_STATUS_RESET: 'myAppsAction/GET_MY_APPS_STATUS_RESET',
}

//* thunk action
//* call api side effect
const getMyApps = (userId) => async (dispatch) => {
  dispatch(getMyAppsBegin());
  try {
    const response = await myAppsApi.getMyApps(userId);

    const myApps = [
      ...response.data
    ]

    dispatch(getMyAppsSuccess(myApps));
  } catch (error) {
    dispatch(getMyAppsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

//* only pure action
const getMyAppsBegin = () => {
  return {
    type: myAppsActionTypes.GET_MY_APPS
  }
}

const getMyAppsSuccess = (myApps) => {
  return {
    type: myAppsActionTypes.GET_MY_APPS_SUCCESS,
    payload: {
      myApps: myApps
    },
  }
}

const getMyAppsFailure = (error) => {
  return {
    type: myAppsActionTypes.GET_MY_APPS_FAILURE,
    payload: {
      error: error
    }
  }
}

// eslint-disable-next-line no-unused-vars
const getMyAppsResetStatus = () => {
  return {
    type: myAppsActionTypes.GET_MY_APPS_STATUS_RESET,
  }
}

export default {
  getMyApps,
  getMyAppsResetStatus,
}
