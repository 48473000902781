import { rolesAppApi } from 'apis';
import compareArray from 'utils/compareArray';
import errorString from '../../utils/errorString';

export const appRoleManageListActionTypes = {
  GET_ROLE_LIST: 'appRoleManageListAction/GET_ROLE_LIST',
  GET_ROLE_LIST_SUCCESS: 'appRoleManageListAction/GET_ROLE_LIST_SUCCESS',
  GET_ROLE_LIST_FAILURE: 'appRoleManageListAction/GET_ROLE_LIST_FAILURE',

  GET_PERMISSION_LIST_SUCCESS: 'appRoleManageListAction/GET_PERMISSION_LIST_SUCCESS',
  GET_PERMISSION_LIST_FAILURE: 'appRoleManageListAction/GET_PERMISSION_LIST_FAILURE',

  CREATE_ROLE_SUCCESS: 'appRoleManageListAction/CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'appRoleManageListAction/CREATE_ROLE_FAILURE',
  RESET_CREATE_ROLE: 'appRoleManageListAction/RESET_CREATE_ROLE',

  GET_UPDATE_ROLE_SUCCESS: 'appRoleManageListAction/GET_UPDATE_ROLE_SUCCESS',
  GET_UPDATE_ROLE_FAILURE: 'appRoleManageListAction/GET_UPDATE_ROLE_FAILURE',
  GET_UPDATE_ROLE_STATUS_RESET: 'appRoleManageListAction/GET_UPDATE_ROLE_STATUS_RESET',
  POST_UPDATE_ROLE_SUCCESS: 'appRoleManageListAction/POST_UPDATE_ROLE_SUCCESS',
  POST_UPDATE_ROLE_FAILURE: 'appRoleManageListAction/POST_UPDATE_ROLE_FAILURE',
  POST_UPDATE_ROLE_STATUS_RESET: 'appRoleManageListAction/POST_UPDATE_ROLE_STATUS_RESET',
  RESET_UPDATE_ROLE: 'appRoleManageListAction/RESET_UPDATE_ROLE',

  DELETE_ROLE_SUCCESS: 'appRoleManageListAction/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'appRoleManageListAction/DELETE_ROLE_FAILURE',
  DELETE_ROLE_STATUS_RESET: 'appRoleManageListAction/DELETE_ROLE_STATUS_RESET',

  ROLE_STATUS_RESET: 'appRoleManageListAction/ROLE_STATUS_RESET',
}
//* get app role list
const getRoleList = (appId) => async dispatch => {
  dispatch(getRoleListBegin());
  try {
    const appRoleListResponse = await rolesAppApi.getFullRoleList(appId);

    const permissionOfRoleList = await Promise.all(
      appRoleListResponse.data.map((item) => {
        return rolesAppApi.getPermissionOfRole(item.id);
      })
    );

    let appRoleList = [];

    for (let i = 0; i < appRoleListResponse.data.length; i++) {
      appRoleList.push(
        {
          ...appRoleListResponse.data[i],
          permission: [...permissionOfRoleList[i].data]
        }
      );
    }

    dispatch(getRoleListSuccess(appRoleList));
  } catch (error) {
    dispatch(getRoleListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getRoleListBegin = () => {
  return {
    type: appRoleManageListActionTypes.GET_ROLE_LIST
  }
}

const getRoleListSuccess = (appRoleList) => {
  return {
    type: appRoleManageListActionTypes.GET_ROLE_LIST_SUCCESS,
    payload: {
      appRoleList: appRoleList
    },
  }
}

const getRoleListFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.GET_ROLE_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

//* get app permission list
const getPermissionList = (appId) => async dispatch => {
  try {
    const appPermissionListResponse = await rolesAppApi.getPermissionList(appId);

    const appPermissionList = [
      ...appPermissionListResponse.data
    ]

    dispatch(getPermissionListSuccess(appPermissionList));
  } catch (error) {
    dispatch(getPermissionListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getPermissionListSuccess = (appPermissionList) => {
  return {
    type: appRoleManageListActionTypes.GET_PERMISSION_LIST_SUCCESS,
    payload: {
      appPermissionList: appPermissionList
    },
  }
}

const getPermissionListFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.GET_PERMISSION_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}
  
//* create app role
const createRole = (roleInfor, appId) => async dispatch => {
  try {
    const appRoleCreateResponse = await rolesAppApi.postCreateRole(roleInfor, appId);

    const appRoleCreate = {
      ...appRoleCreateResponse.data,
    }

    await Promise.all(
      roleInfor.permission.map((item) => {
        return rolesAppApi.addPermissionToRole(appRoleCreate.id, item.id);
      })
    );
    dispatch(createRoleSuccess({ ...appRoleCreate, permission: roleInfor.permission }));
  } catch (error) {
    dispatch(createRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const createRoleSuccess = (appRoleCreateInfor) => {
  return {
    type: appRoleManageListActionTypes.CREATE_ROLE_SUCCESS,
    payload: {
      appRoleCreateInfor: appRoleCreateInfor
    },
  }
}

const createRoleFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.CREATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetCreateRole = () => {
  return {
    type: appRoleManageListActionTypes.RESET_CREATE_ROLE,
  }
}

//* get update app role
const getUpdateRole = (roleId) => async dispatch => {
  try {

    const [appRoleInfor, permissionOfRole] = await Promise.all([rolesAppApi.getUpdateRole(roleId), rolesAppApi.getPermissionOfRole(roleId)]);
    const appRoleGetUpdate = {
      ...appRoleInfor.data,
      permission: [...permissionOfRole.data]
    }

    dispatch(getUpdateRoleSuccess(appRoleGetUpdate));
  } catch (error) {
    dispatch(getUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getUpdateRoleSuccess = (appRoleGetUpdate) => {
  return {
    type: appRoleManageListActionTypes.GET_UPDATE_ROLE_SUCCESS,
    payload: {
      appRoleGetUpdate: appRoleGetUpdate
    },
  }
}

const getUpdateRoleFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.GET_UPDATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

//* post update app role
// const postUpdateRole = (roleInfor, appId) => async dispatch => {
//   try {
//     await rolesAppApi.postUpdateRole(roleInfor, appId);

//     const listPermissionOfRoleId = {
//       listPermissionOfRoleId: roleInfor.permission.map(item => item.id)
//     }
//     await rolesAppApi.updatePermissionsOfRole(roleInfor.id, listPermissionOfRoleId);

//     // await rolesAppApi.deleteAllPermissionOfRole(roleInfor.id);
//     // await Promise.all(
//     //   roleInfor.permission.map((item) => {
//     //     return rolesAppApi.addPermissionToRole(roleInfor.id, item.id);
//     //   })
//     // );

//     dispatch(postUpdateRoleSuccess());
//   } catch (error) {
//     dispatch(postUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
//   }
// }

//* handle update speed
const postUpdateRole = (roleInfor, appId, oldListPermissionOfRoleId) => async dispatch => {
  try {
    await rolesAppApi.postUpdateRole(roleInfor, appId);

    const newListPermissionOfRoleId = roleInfor.permission.map(item => item.id);

    const listPermissionOfRoleCompareId = compareArray(oldListPermissionOfRoleId, newListPermissionOfRoleId);
    const listPermissionOfRoleId = {
      listPermissionOfRoleDeleteId: listPermissionOfRoleCompareId.listItemDelete,
      listPermissionOfRoleAddId: listPermissionOfRoleCompareId.listItemAdd
    };

    await rolesAppApi.updatePermissionsOfRole(roleInfor.id, listPermissionOfRoleId);

    dispatch(postUpdateRoleSuccess());
  } catch (error) {
    dispatch(postUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const postUpdateRoleSuccess = () => {
  return {
    type: appRoleManageListActionTypes.POST_UPDATE_ROLE_SUCCESS,
  }
}

const postUpdateRoleFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.POST_UPDATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

//* reset update app role
const resetUpdateRole = () => {
  return {
    type: appRoleManageListActionTypes.RESET_UPDATE_ROLE,
  }
}

//* delete app role
const deleteRole = (roleId) => async dispatch => {
  try {
    const response = await rolesAppApi.deleteRole(roleId);
    const roleDeleted = {
      ...response.data,
    }

    dispatch(deleteRoleSuccess(roleDeleted.id));
  } catch (error) {
    dispatch(deleteRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const deleteRoleSuccess = (appRoleDeleteId) => {
  return {
    type: appRoleManageListActionTypes.DELETE_ROLE_SUCCESS,
    payload: {
      appRoleDeleteId: appRoleDeleteId
    },
  }
}

const deleteRoleFailure = (error) => {
  return {
    type: appRoleManageListActionTypes.DELETE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetDeletionStatus = () => {
  return {
    type: appRoleManageListActionTypes.DELETE_ROLE_STATUS_RESET
  }
}


const resetRoleStatus = () => {
  return {
    type: appRoleManageListActionTypes.ROLE_STATUS_RESET
  }
}

export default {
  getRoleList,
  getPermissionList,

  createRole,
  resetCreateRole,

  getUpdateRole,
  postUpdateRole,
  resetUpdateRole,

  deleteRole,
  resetDeletionStatus,

  resetRoleStatus,
}
