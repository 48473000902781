import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import userManageListReducer from './userManageListReducer';
import userCreationReducer from './userCreationReducer';
import userUpdateReducer from './userUpdateReducer';
import profileUpdateReducer from './profileUpdateReducer';
import changePassword from './changePasswordReducer';
import resetPassword from './resetPasswordReducer';
import forgotPassword from './forgotPasswordActionReducer'
import permissionReducer from './permissionReducer';
import appManagementReducer from './appManagementReducer';
import roleManageListReducer from './roleManageListReducer';
import appPermissionReducer from './appPermissionReducer';
import myAppsReducer from './myAppsReducer';
import appRoleManageListReducer from './appRoleManageListReducer';
import navBarReducer from './navBarReducer';
import appUserReducer from './appUserReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  userManage: userManageListReducer,
  userCreate: userCreationReducer,
  userUpdate: userUpdateReducer,
  profile: profileUpdateReducer,
  changePassword: changePassword,
  resetPassword: resetPassword,
  forgotPassword: forgotPassword,
  permission: permissionReducer,
  appManage: appManagementReducer,
  roleManage: roleManageListReducer,
  appPermission: appPermissionReducer,
  myApps: myAppsReducer,
  appRoleManage: appRoleManageListReducer,
  navBar: navBarReducer,
  appUser:appUserReducer
});

export default rootReducer;
