import { myAppsActionTypes } from '../actions';

const initialState = {};

const myAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case myAppsActionTypes.GET_MY_APPS: {
      return {
        getMyAppsStatus: 'waiting',
      }
    }

    case myAppsActionTypes.GET_MY_APPS_SUCCESS: {
      return {
        ...state,
        myApps: action.payload.myApps,
        getMyAppsStatus: 'success'
      }
    }

    case myAppsActionTypes.GET_MY_APPS_FAILURE: {
      return {
        getMyAppsError: action.payload.error,
        myApps: [],
        getMyAppsStatus: 'error'
      }
    }

    case myAppsActionTypes.GET_MY_APPS_STATUS_RESET: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default myAppReducer;
