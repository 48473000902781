import { resetPasswordApi } from 'apis';
import errorString from '../../utils/errorString';

export const resetPasswordActionTypes = {
  RESET_PASSWORD: 'resetPasswordAction/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'resetPasswordAction/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE:'resetPasswordAction/RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_RESET: 'resetPasswordAction/RESET_PASSWORD_RESET',
  CHECK_TOKEN: 'resetPasswordAction/CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'resetPasswordAction/CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILURE: 'resetPasswordAction/CHECK_TOKEN_FAILURE',
  CHECK_TOKEN_RESET: 'resetPasswordAction/CHECK_TOKEN_RESET',
}

//* reset password
const resetPassword = (password,token) => async dispatch => {
  //* notify begin action to component
  dispatch(resetPasswordBegin());
  try {
    const resultResetPassword = await resetPasswordApi.resetPassword(password,token);
    dispatch(resetPasswordSuccess(resultResetPassword.data));
    // dispatch(resetPasswordReset());
  } catch (error) {
    const check = Array.isArray(error.response.data.message);
    if(check === true){
      dispatch(resetPasswordFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.matches))));
    }else{
      dispatch(resetPasswordFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    }
  }
}

const resetPasswordClear = () => dispatch => {
  dispatch(resetPasswordReset())
}

//* check Token
const checkToken = (token) => async dispatch => {
  //* notify begin action to component
  dispatch(checkTokenBegin());
  try {
    const check = await resetPasswordApi.checkToken(token);
    dispatch(checkTokenSuccess(check.data));
    // dispatch(resetPasswordReset());
  } catch (error) {
    dispatch(checkTokenFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const checkTokenClear = () => dispatch => {
  dispatch(checkTokenReset());
}


//* reset password action
const resetPasswordBegin = () => {
  return {
    type: resetPasswordActionTypes.RESET_PASSWORD
  }
}

const resetPasswordSuccess = (resultResetPassword) => {
  return {
    type: resetPasswordActionTypes.RESET_PASSWORD_SUCCESS,
    payload: {
      resultResetPassword: resultResetPassword
    },
  }
}

const resetPasswordFailure = (error) => {
  return {
    type: resetPasswordActionTypes.RESET_PASSWORD_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetPasswordReset = () => {
  return {
    type: resetPasswordActionTypes.RESET_PASSWORD_RESET,
    payload: {
    },
  }
}


//* check Token action
const checkTokenBegin = () => {
  return {
    type: resetPasswordActionTypes.CHECK_TOKEN
  }
}

const checkTokenSuccess = (result) => {
  return {
    type: resetPasswordActionTypes.CHECK_TOKEN_SUCCESS,
    payload: {
      result: result
    },
  }
}

const checkTokenFailure = (error) => {
  return {
    type: resetPasswordActionTypes.CHECK_TOKEN_FAILURE,
    payload: {
      error: error
    },
  }
}

const checkTokenReset = () => {
  return {
    type: resetPasswordActionTypes.CHECK_TOKEN_RESET,
    payload: {
    },
  }
}


export default {
  resetPassword,
  checkToken,
  resetPasswordClear,
  checkTokenClear
}
