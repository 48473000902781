import { usersApi } from 'apis';
import errorString from '../../utils/errorString';

export const forgotPasswordActionTypes = {
  FORGOT_PASSWORD: 'forgotPasswordAction/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'forgotPasswordAction/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'forgotPasswordAction/FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_RESET: 'forgotPasswordAction/FORGOT_PASSWORD_RESET'
}

//* forgot password action
const forgotPassword = (value) => async dispatch => {
  //* notify begin action to component
  dispatch(forgotPasswordBegin());
  try {
    const passwordsApi = await usersApi.checkEmail(value);
    dispatch(forgotPasswordSuccess(passwordsApi));
    // dispatch(forgotPasswordReset());
  } catch (error) {
    dispatch(forgotPasswordFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.isEmail))));
  }
}

//* forgot password
const forgotPasswordBegin = () => {
  return {
    type: forgotPasswordActionTypes.FORGOT_PASSWORD
  }
}

const forgotPasswordSuccess = (check) => {
  return {
    type: forgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: {
      check: check
    },
  }
}

const forgotPasswordFailure = (error) => {
  return {
    type: forgotPasswordActionTypes.FORGOT_PASSWORD_FAILURE,
    payload: {
      error: error
    },
  }
}


export default {
  forgotPassword,
}
