import { sessionApi } from 'apis';
import errorString from '../../utils/errorString';

/* eslint-disable no-unused-vars */
export const sessionActionTypes = {
  SESSION_LOGIN: 'sessionActions/SESSION_LOGIN',
  SESSION_LOGIN_FAILURE: 'sessionActions/SESSION_LOGIN_FAILURE',
  SESSION_LOGIN_SUCCESS: 'sessionActions/SESSION_LOGIN_SUCCESS',

  SESSION_LOGOUT: 'sessionActions/SESSION_LOGOUT',

  SESSION_GET_USER_INFO: 'sessionActions/SESSION_GET_USER_INFO',
  SESSION_GET_USER_INFO_SUCCESS: 'sessionActions/SESSION_GET_USER_INFO_SUCCESS',
  SESSION_GET_USER_INFO_FAILURE: 'sessionActions/SESSION_GET_USER_INFO_FAILURE',

  SESSION_RESET: 'sessionActions/SESSION_RESET'
};

const login = (username, password) => async dispatch => {
  dispatch(sessionLoginBegin());

  try {
    const response = await sessionApi.login(username, password);
    const accessToken = response.data.accessToken;

    if(accessToken) {
      localStorage.setItem('accessToken', accessToken);

      dispatch(sessionLoginSuccess());
      dispatch(getUserInfo());
    } else {
      dispatch(sessionLoginFailure(response.data.message));
    }

  } catch (error) {
    dispatch(sessionLoginFailure('Login failed'));
  }
};

const sessionLoginBegin = () => {
  return {
    type: sessionActionTypes.SESSION_LOGIN
  }
};

const sessionLoginSuccess = () => {
  return {
    type: sessionActionTypes.SESSION_LOGIN_SUCCESS,
  };
};

const sessionLoginFailure = (error) => {
  return {
    type: sessionActionTypes.SESSION_LOGIN_FAILURE,
    payload: {
      error: error
    }
  }
};

const logout = () => dispatch => {
  localStorage.removeItem('accessToken');
  dispatch({
    type: sessionActionTypes.SESSION_LOGOUT
  });
};

const getUserInfo = () => async dispatch => {
  dispatch(getUserInfoBegin());

  try {
    const response = await sessionApi.getUserLogin();

    const user = response.data.user;
    if(!user) {
      dispatch(getUserInfoFailure(errorString('ErrorCode: ' + response.error.data.statusCode + '!  ' + JSON.stringify(response.error.data.message))));
    }

    dispatch(getUserInfoSuccess(user));

  } catch(error) {
    dispatch(getUserInfoFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }  
};

const getUserInfoBegin = () => {
  return {
    type: sessionActionTypes.SESSION_GET_USER_INFO
  }
};

const getUserInfoSuccess = (user) => {
  // eslint-disable-next-line no-undef
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // console.log(baseUrl);

  return {
    type: sessionActionTypes.SESSION_GET_USER_INFO_SUCCESS,
    payload: { 
      user: { 
        ...user,
        avatar: `${baseUrl}/public/images/avatars/${user.id}.png?t=${new Date().getTime()}`
      },
    }
  }
};

const getUserInfoFailure = (error) => {
  return {
    type: sessionActionTypes.SESSION_GET_USER_INFO_FAILURE,
    payload: {
      error: error
    }
  }
};

const sessionReset = (error) => {
  return {
    type: sessionActionTypes.SESSION_RESET,
  }
};

export default {
  logout,
  login,
  getUserInfo,
  sessionReset
};
