import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import { intersection } from 'lodash'

const AuthGuard = props => {
  const { permissions, children } = props;

  const session = useSelector(state => state.session);
  const router = useRouter();

  useEffect(() => {

    if (!session.loggedIn || !session.user) {
      router.history.push('/auth/login');
      return;
    }

    // console.log(session.user.permissions);
    // console.log(permissions);

    if (intersection(session.user.permissions, permissions).sort().toString().replace(/[,]+/g,"") !== permissions.sort().toString().replace(/[,]+/g,"")) {
      router.history.push('/errors/error-401');
      return;
    }

    // eslint-disable-next-line
  }, [router]);

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.array.isRequired
};

AuthGuard.defaultProps = {
  permissions: []
};

export default AuthGuard;
