import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

const DialogConfirm = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // eslint-disable-next-line
  const { open, className, message, handleClose, handleConfirm, ...rest } = props;

  return (
    <div
      className={className}
      {...rest}
    >
      <Dialog
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id="responsive-dialog-title"
        >
          {'Do you want to continue ?'}
        </DialogTitle>

        <DialogContent hidden={message ? false : true}>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            hidden={!handleClose}
            onClick={handleClose}
            variant="contained"
          >
            No !!!
          </Button>
          <Button 
            autoFocus
            color="primary"
            onClick={handleConfirm}
            variant="contained"
          >
            Yes, do it
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogConfirm.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired
};

export default DialogConfirm;
