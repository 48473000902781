import { permissionsAppApi } from 'apis';
import errorString from '../../utils/errorString';

export const appPermissionActionTypes = {
  GET_APP_PERMISSIONS: 'appPermissionAction/GET_APP_PERMISSIONS',
  GET_APP_PERMISSIONS_SUCCESS: 'appPermissionAction/GET_APP_PERMISSIONS_SUCCESS',
  GET_APP_PERMISSIONS_FAILURE: 'appPermissionAction/GET_APP_PERMISSIONS_FAILURE',
  GET_APP_PERMISSIONS_STATUS_RESET: 'appPermissionAction/GET_APP_PERMISSIONS_STATUS_RESET',
  RESET_MANAGE_PERMISSION_IN_APP: 'appPermissionAction/RESET_MANAGE_PERMISSION_IN_APP',
}

//* thunk action
//* call api side effect
const getAppPermissions = (appId) => async (dispatch) => {
  dispatch(getAppPermissionsBegin());
  try {
    const permissionResponse = await permissionsAppApi.getFullAppPermissionList(appId);

    const permission = [
      ...permissionResponse.data
    ]

    dispatch(getAppPermissionsSuccess(permission));
  } catch (error) {
    dispatch(getAppPermissionsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

//* only pure action
const getAppPermissionsBegin = () => {
  return {
    type: appPermissionActionTypes.GET_APP_PERMISSIONS
  }
}

const getAppPermissionsSuccess = (permission) => {
  return {
    type: appPermissionActionTypes.GET_APP_PERMISSIONS_SUCCESS,
    payload: {
      permission: permission
    },
  }
}

const getAppPermissionsFailure = (error) => {
  return {
    type: appPermissionActionTypes.GET_APP_PERMISSIONS_FAILURE,
    payload: {
      error: error
    }
  }
}

const resetManagePermissionInApp = () => {
  return {
    type: appPermissionActionTypes.RESET_MANAGE_PERMISSION_IN_APP
  }
}

export default {
  getAppPermissions,
  resetManagePermissionInApp,
}
