import { usersApi } from 'apis';
import errorString from '../../utils/errorString';

export const userManageListActionTypes = {
  GET_USER_LIST: 'userManageListAction/GET_USER_LIST',
  GET_USER_LIST_SUCCESS: 'userManageListAction/GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE: 'userManageListAction/GET_USER_LIST_FAILURE',

  DELETE_USER: 'userManageListAction/DELETE_USER',
  DELETE_USER_SUCCESS: 'userManageListAction/DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'userManageListAction/DELETE_USER_FAILURE',
  RESET_MANAGE_USER_STATUS: 'userManageListAction/RESET_MANAGE_USER_STATUS',
}

const getUserList = () => async dispatch => {
  //* notify begin action to component
  dispatch(getUserListBegin());

  try {
    const response = await usersApi.getUserList();

    const userListReponse = [
      ...response.data
    ]

    for(let i=0; i<userListReponse.length; i++){
      userListReponse[i] = {
        ...userListReponse[i],
        fullName: userListReponse[i].firstName + ' ' + userListReponse[i].lastName
      }
    }

    const userList = [
      ...userListReponse,
    ]

    dispatch(getUserListSuccess(userList));
  } catch (error) {
    dispatch(getUserListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getUserListBegin = () => {
  return {
    type: userManageListActionTypes.GET_USER_LIST
  }
}

const getUserListSuccess = (lstUser) => {
  return {
    type: userManageListActionTypes.GET_USER_LIST_SUCCESS,
    payload: {
      lstUser: lstUser
    },
  }
}

const getUserListFailure = (error) => {
  return {
    type: userManageListActionTypes.GET_USER_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

const deleteUser = (userId) => async (dispatch) => {
  dispatch(deleteUserBegin());
  try {
    const userIdDeletedResponse = await usersApi.deleteUser(userId);

    const userIdDeleted = {
      ...userIdDeletedResponse.data.id
    }

    dispatch(deleteUserSuccess(userIdDeleted));
  } catch (error) {
    dispatch(deleteUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const deleteUserBegin = () => {
  return {
    type: userManageListActionTypes.DELETE_USER
  }
}

const deleteUserSuccess = (userIdDeleted) => {
  return {
    type: userManageListActionTypes.DELETE_USER_SUCCESS,
    payload: {
      userIdDeleted: userIdDeleted
    }
  }
}

const deleteUserFailure = (error) => {
  return {
    type: userManageListActionTypes.DELETE_USER_FAILURE,
    payload: {
      error: error
    }
  }
}

const resetManageUserStatus = (error) => {
  return {
    type: userManageListActionTypes.RESET_MANAGE_USER_STATUS,
  }
}

export default {
  getUserList,
  deleteUser,
  resetManageUserStatus,
}
