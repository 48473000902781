import axios from 'utils/axios';

const getUserList = () => {
  return axios.get(`/users`);
}

const getRoleAtmList = () => {
  return axios.get('/role-atms');
}

const getPermissionAtmList = () => {
  return axios.get('/permission-atms');
}

const createUser = (user) => {
  return axios.post(`/users`, user);
}

const getUser = (id) => {
  return axios.get(`/users/${id}`);
}

const getRoleAtmsOfUser = (id) => {
  return axios.get(`/users/${id}/role-atms`);
}

const getPermissionAtmsOfUser = (id) => {
  return axios.get(`/users/${id}/permission-atms`);
}

const updateUser = (user) => {
  return axios.patch(`/users/${user.id}`, user);
}

const deleteAllRoleAtmOfUser = (userId) => {
  return axios.delete(`/role-atms/users/${userId}`);
}

const addRoleAtmToUser = (userId, roles) => {
  return axios.post(`/role-atms/users/${userId}`, roles);
}

const updateRoleAtmInUser = (userId, roles) => {
  return axios.patch(`/role-atms/users/${userId}`, roles);
}

const deleteAllPermissionAtmOfUser = (userId) => {
  return axios.delete(`/permission-atms/users/${userId}`);
}

const addPermissionAtmToUser = (userId, permissions) => {
  return axios.post(`/permission-atms/users/${userId}`, permissions);
}

const updatePermissionAtmInUser = (userId, permissions) => {
  return axios.patch(`/permission-atms/users/${userId}`, permissions);
}

const deleteUser = (userId) => {
  return axios.delete(`/users/${userId}`);
}

const checkEmail = (email) => {
  return axios.post(`/auth/forgot-password`,{email});
}

export default {
  getUserList,
  getRoleAtmList,
  getPermissionAtmList,
  createUser,
  getUser,
  getRoleAtmsOfUser,
  getPermissionAtmsOfUser,
  deleteAllRoleAtmOfUser,
  updateRoleAtmInUser,
  updateUser,
  addRoleAtmToUser,
  addPermissionAtmToUser,
  deleteAllPermissionAtmOfUser,
  updatePermissionAtmInUser,
  deleteUser,
  checkEmail
}
