import axios from 'utils/axios';

const login = (username, password) => {  
  return axios.post('/auth/sign-in', {
    username,
    password
  });
}

const getUserLogin = () => {
  return axios.get('/auth');
}

export default {
  login,
  getUserLogin
}
