import { usersApi } from 'apis';
import errorString from '../../utils/errorString';

export const userCreateActionTypes = {
  CREATE_USER: 'userCreateAction/CREATE_USER',
  CREATE_USER_SUCCESS: 'userCreateAction/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'userCreateAction/CREATE_USER_FAILURE',
  CREATE_USER_STATUS_RESET: 'userCreateAction/CREATE_USER_STATUS_RESET',
}

//* thunk action
//* call api side effect
const createUser = (user) => async (dispatch) => {
  dispatch(createUserBegin());
  try {
    const userCreateResponse = await usersApi.createUser(user);

    const userCreate = {
      ...userCreateResponse.data
    }
    
    dispatch(createUserSuccess(userCreate));
  } catch (error) {
    if (typeof error.response.data.message === 'string') {
      dispatch(createUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    } else {
      dispatch(createUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.matches))));
    }
  }
}

//* only pure action
const createUserBegin = () => {
  return {
    type: userCreateActionTypes.CREATE_USER
  }
}

const createUserSuccess = (user) => {
  return {
    type: userCreateActionTypes.CREATE_USER_SUCCESS,
    payload: {
      user: user
    },
  }
}

const createUserFailure = (error) => {
  return {
    type: userCreateActionTypes.CREATE_USER_FAILURE,
    payload: {
      error: error
    }
  }
}

const resetCreationStatus = () => {
  return {
    type: userCreateActionTypes.CREATE_USER_STATUS_RESET
  }
}

export default {
  createUser,
  resetCreationStatus
}
