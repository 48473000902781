import axios from 'utils/axios';

const getRoleList = (appId) => {
  return axios.get(`/apps/${appId}/roles`);
}

const getFullRoleList = (appId) => {
  return axios.get(`/apps/${appId}/roles/full/${true}`);
}

const getPermissionOfRole = (roleId) => {
  return axios.get(`/roles/${roleId}/permissions`);
}

const getPermissionList = (appId) => {
  return axios.get(`/apps/${appId}/permissions`);
}

const postCreateRole = (roleInfor, appId) => {
  return axios.post(`/roles/apps/${appId}`, roleInfor);
}

const getUpdateRole = (roleId) => {
  return axios.get(`/roles/${roleId}`);
}

const postUpdateRole = (roleInfor, appId) => {
  return axios.patch(`/roles/${roleInfor.id}/apps/${appId}`, roleInfor);
}

const deleteAllPermissionOfRole = (roleId) => {
  return axios.delete(`/roles/${roleId}/permissions`);
}

const addPermissionToRole = (roleId, permissionId) => {
  return axios.post( `/roles/${roleId}/permissions/${permissionId}`);
}

const updatePermissionsOfRole = (roleId, listPermissionOfRoleId) => {
  return axios.patch( `/roles/${roleId}/permission-list`, listPermissionOfRoleId);
}

const deleteRole = (roleId) => {
  return axios.delete(`/roles/${roleId}`);
}

export default {
  getPermissionList,
  getRoleList,
  getFullRoleList,
  getPermissionOfRole,
  postCreateRole,
  getUpdateRole,
  postUpdateRole,
  deleteAllPermissionOfRole,
  addPermissionToRole,
  updatePermissionsOfRole,
  deleteRole,
}
