import axios from 'utils/axios';

const checkToken = (token) => {
  return axios.get(`/auth/check-token/${token}`,{
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
}

const resetPassword = (password,token) => {
  return axios.post(`/auth/reset-password`,
  {password,token},
  {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
}

export default {
  checkToken,
  resetPassword
}
