import { userUpdateActionTypes } from '../actions';

const initialState = {
  user: {
    id: '',
    lstRoleAtm: [],
    lstPermissionAtm: [],
  },
  lstRoleAtm: [],
  lstPermissionAtm: [],
  error: null,
  status: '',
  updateUserStatus: '',
  updated: false
};

const userUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case userUpdateActionTypes.GET_USER: {
      return {
        ...state,
        status: 'waiting'
      }
    }

    case userUpdateActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        status: 'success'
      }
    }

    case userUpdateActionTypes.GET_USER_FAILURE: {
      return {
        ...initialState,
        error: action.payload.error,
        status: 'failure'
      }
    }

    case userUpdateActionTypes.GET_ROLE_ATM_LIST: {
      return {
        ...state,
        status: 'waiting'
      }
    }

    case userUpdateActionTypes.GET_ROLE_ATM_LIST_SUCCESS: {
      return {
        ...state,
        lstRoleAtm: action.payload.lstRoleAtm,
        status: 'success'
      }
    }

    case userUpdateActionTypes.GET_ROLE_ATM_LIST_FAILURE: {
      return {
        ...initialState,
        error: action.payload.error,
        status: 'failure'
      }
    }

    case userUpdateActionTypes.GET_PERMISSION_ATM_LIST: {
      return {
        ...state,
        status: 'waiting'
      }
    }

    case userUpdateActionTypes.GET_PERMISSION_ATM_LIST_SUCCESS: {
      return {
        ...state,
        lstPermissionAtm: action.payload.lstPermissionAtm,
        status: 'success'
      }
    }

    case userUpdateActionTypes.GET_PERMISSION_ATM_LIST_FAILURE: {
      return {
        ...initialState,
        error: action.payload.error,
        status: 'failure'
      }
    }

    case userUpdateActionTypes.UPDATE_USER: {
      return {
        ...state,
        status: 'waiting',
        updated: false
      }
    }

    case userUpdateActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        status: 'success',
        updateUserStatus: 'success',
        updated: true
      }
    }

    case userUpdateActionTypes.UPDATE_USER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'failure'
      }
    }

    case userUpdateActionTypes.RESET_UPDATE_USER_STATUS: {
      return {
        ...state,
        updateUserStatus: '',
        error: null
      }
    }

    case userUpdateActionTypes.RESET_UPDATE_USER: {
      return initialState
    }

    default: {
      return state;
    }
  }
}

export default userUpdateReducer;
