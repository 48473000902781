export default (oldArray, newArray) => {
  // eslint-disable-next-line
  const listItemDelete = oldArray.filter(item => {
    if (newArray.indexOf(item) === -1) return item;
  });
  
  // eslint-disable-next-line
  const listItemAdd = newArray.filter(item => {
    if (oldArray.indexOf(item) === -1) return item;
  });

  return { 
    listItemDelete : listItemDelete,
    listItemAdd : listItemAdd 
  }
}
