import { appPermissionActionTypes } from '../actions';

const initialState = {
  permissions: []
};

const appPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case appPermissionActionTypes.GET_APP_PERMISSIONS: {
      return {
        status: 'waiting',
      }
    }

    case appPermissionActionTypes.GET_APP_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissions: action.payload.permission,
        status: 'success'
      }
    }

    case appPermissionActionTypes.GET_APP_PERMISSIONS_FAILURE: {
      return {
        error: action.payload.error,
        permissions: [],
        status: 'error'
      }
    }

    case appPermissionActionTypes.RESET_MANAGE_PERMISSION_IN_APP: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default appPermissionReducer;
