/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Typography
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import useRouter from 'utils/useRouter';

import { sessionAction } from 'store/actions';
import { DialogConfirmSignOut } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  logoImage: {
    height: '40px',
    width: '40px',
  },
  logoText: {
    marginLeft: '5px',
    color: 'white',
    fontSize: '20px'
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();

  //* Dialog Confirm Sign Out
  const dialogConfirmDefault = { open: false, message: '' };
  const [dialogConfirmValue, setDialogConfirmValue] = useState(dialogConfirmDefault);
  const handleOpenDialogConfirm = () => setDialogConfirmValue({ open: true, message: 'This will end your session' });
  const handleSubmitDialogConfirm = async () => handleLogout();
  const handleCloseDialogConfirm = () => setDialogConfirmValue(dialogConfirmDefault);

  const handleLogout = () => {
    history.push('/auth/login');
    dispatch(sessionAction.logout());
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.logoImage}
            src="/images/logo.png"
          />
        </RouterLink>
        <Typography className={classes.logoText}>PHP Automation</Typography>
        <div className={classes.flexGrow} />        
        <Hidden mdDown>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleOpenDialogConfirm}
          >
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <DialogConfirmSignOut
        handleClose={handleCloseDialogConfirm}
        handleConfirm={handleSubmitDialogConfirm}
        message={dialogConfirmValue.message}
        open={dialogConfirmValue.open}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
