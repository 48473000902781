import axios from 'utils/axios';

const getProfile = (id) => {
  return axios.get(`/users/${id}/profile`);
}

const updateProfile = (profileInfor) => {
  return axios.patch(`/users/${profileInfor.id}/profile`, profileInfor);
}

const updateAvatar = (profile) => {
  var formData = new FormData();
  formData.append('avatar', profile.avatar);
  return axios.patch(`/users/${profile.id}/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export default {
  getProfile,
  updateProfile,
  updateAvatar,
}
