/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';
import WbSunnyIcon from '@material-ui/icons/WbSunny';

export default (currentAppId) => {
  return [
    {
      title: 'Pages',
      pages: [
        {
          title: 'Profile',
          href: '/profile',
          icon: AccountBoxIcon
        },
        {
          title: 'Change password',
          href: '/change-password',
          icon: VpnKeyIcon,
        },
        {
          title: 'My App',
          href: '/my-app',
          icon: BusinessCenterOutlinedIcon
        },
        {
          title: 'Permission',
          href: '/permissions',
          icon: WbIridescentIcon
        },
        {
          title: 'Role',
          href: '/roles',
          icon: WbSunnyIcon,
        },
        {
          title: 'User',
          href: '/users',
          icon: PeopleIcon,
          children: [
            {
              title: 'User Management',
              href: '/users'
            },
            {
              title: 'Create User',
              href: '/users/create'
            },
          ]
        },
        {
          title: 'App',
          href: '/apps',
          icon: SettingsIcon,
          children: [
            {
              title: 'App Management',
              href: '/apps'
            },
            ...appNavConfig(currentAppId)
          ]
        }
      ]
    }
  ];
};

const appNavConfig = (currentAppId) => {
  if(!currentAppId) return [];
  
  return [
    {
      title: 'User',
      href: `/apps/${currentAppId}/users`,
    },
    {
      title: 'Permission',
      href: `/apps/${currentAppId}/permissions`
    },
    {
      title: 'Role',
      href: `/apps/${currentAppId}/roles`
    }
  ];
}
