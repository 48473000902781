import axios from 'utils/axios';

const getRoleList = () => {
  return axios.get('/role-atms');
}

const getFullRoleList = () => {
  return axios.get(`/role-atms/full/${true}`);
}

const getPermissionList = () => {
  return axios.get('/permission-atms');
}

const getPermissionOfRoleAtm = (roleId) => {
  return axios.get(`role-atms/${roleId}/permissions`);
}

const addPermissionToRoleAtm = (roleId, permissionId) => {
  return axios.post(`role-atms/${roleId}/permission-atms/${permissionId}`);
}

const deleteAllPermissionOfRoleAtm = (roleId) => {
  return axios.delete(`role-atms/${roleId}/permission-atms`);
}

const postCreateRole = (roleInfor) => {
  return axios.post('/role-atms', roleInfor);
}

const getUpdateRole = (roleId) => {
  return axios.get(`/role-atms/${roleId}`);
}

const postUpdateRole = (roleInfor) => {
  return axios.patch(`/role-atms/${roleInfor.id}`, roleInfor);
}

const postUpdatePermissionOfRoleAtm = (roleId, listPermissionOfRoleAtmId) => {
  return axios.patch(`role-atms/${roleId}/permission-atm-list`, listPermissionOfRoleAtmId);
}

const deleteRole = (roleId) => {
  return axios.delete(`/role-atms/${roleId}`);
}

export default {
  getFullRoleList,
  getPermissionList,
  getRoleList,
  postCreateRole,
  getUpdateRole,
  postUpdateRole,
  postUpdatePermissionOfRoleAtm,
  deleteRole,
  getPermissionOfRoleAtm,
  deleteAllPermissionOfRoleAtm,
  addPermissionToRoleAtm,
}
