import { userCreateActionTypes } from '../actions';

const initialState = {};

const userCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case userCreateActionTypes.CREATE_USER: {
      return {
        status: 'waiting',
      }
    }

    case userCreateActionTypes.CREATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        status: 'success'
      }
    }

    case userCreateActionTypes.CREATE_USER_FAILURE: {
      return {
        error: action.payload.error,
        status: 'error'
      }
    }

    case userCreateActionTypes.CREATE_USER_STATUS_RESET: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default userCreationReducer;
