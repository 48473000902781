import { navBarActionTypes } from '../actions';

const initialState = {};

const appPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case navBarActionTypes.CHANGE_CURRENT_APP_ID: {
      return {
        ...state,
        currentAppId: action.payload.currentAppId
      }
    }


    default: {
      return state;
    }
  }
};

export default appPermissionReducer;
