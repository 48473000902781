/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/profile" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('views/ForgotPassword'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('views/ResetPassword'))
      },
      {
        path: '/auth/reset-success',
        exact: true,
        component: lazy(() => import('views/ResetSuccess'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*/',
    component: DashboardLayout,
    routes: [
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/change-password',
        exact: true,
        component: lazy(() => import('views/ChangePassword'))
      },
      {
        path: '/my-app',
        exact: true,
        component: lazy(() => import('views/MyApp'))
      },
      {
        path: '/permissions',
        exact: true,
        component: lazy(() => import('views/Permission'))
      },
      {
        path: '/roles',
        exact: true,
        component: lazy(() => import('views/RoleManagement'))
      },
      {
        path: '/users',
        exact: true,
        component: lazy(() => import('views/UserManagement'))
      },
      {
        path: '/users/create',
        exact: true,
        component: lazy(() => import('views/UserCreate'))
      },
      {
        path: '/users/update/:id',
        exact: true,
        component: lazy(() => import('views/UserUpdate'))
      },
      {
        path: '/apps',
        exact: true,
        component: lazy(() => import('views/AppManagement'))
      },
      {
        path: '/apps/:id/users',
        exact: true,
        component: lazy(() => import('views/AppUser'))
      },
      {
        path: '/apps/:id/permissions',
        exact: true,
        component: lazy(() => import('views/AppPermission'))
      },
      {
        path: '/apps/:id/roles',
        exact: true,
        component: lazy(() => import('views/AppRole'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      },
    ]
  }
];

export default routes;
