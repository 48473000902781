import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';

import rootReducer from 'store/reducers';

// const loggerMiddleware = createLogger();

// eslint-disable-next-line
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

// eslint-disable-next-line no-unused-vars
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
}

// const persistentState = loadFromLocalStorage();
// eslint-disable-next-line
const persistentState = {};

//export default function configureStore(preloadedState = persistentState) {
export default function configureStore(preloadedState) {

  const middlewares = [thunkMiddleware]; // loggerMiddleware

  // eslint-disable-next-line no-undef
  const composeEnhancer = process.env.NODE_ENV === 'production' ? compose : composeWithDevTools;

  const middlewareEnhancer = composeEnhancer(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  // store.subscribe(() => saveToLocalStorage(store.getState()));
  return store;
}
