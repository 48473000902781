import { profileApi } from 'apis';
import errorString from '../../utils/errorString';

export const profileUpdateActionTypes = {
  GET_PROFILE: 'profileUpdateAction/GET_PROFILE',
  GET_PROFILE_SUCCESS: 'profileUpdateAction/GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'profileUpdateAction/GET_PROFILE_FAILURE',

  UPDATE_AVATAR: 'profileUpdateAction/UPDATE_AVATAR',
  UPDATE_AVATAR_SUCCESS: 'profileUpdateAction/UPDATE_AVATAR_SUCCESS',
  UPDATE_AVATAR_FAILURE: 'profileUpdateAction/UPDATE_AVATAR_FAILURE',
  UPDATE_AVATAR_STATUS_RESET: 'profileUpdateAction/UPDATE_AVATAR_STATUS_RESET',

  UPDATE_PROFILE: 'profileUpdateAction/UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'profileUpdateAction/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'profileUpdateAction/UPDATE_PROFILE_FAILURE',
  UPDATE_PROFILE_STATUS_RESET: 'profileUpdateAction/UPDATE_PROFILE_STATUS_RESET',
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const getProfile = (id) => async (dispatch) => {
  try {
    const profileGet = await profileApi.getProfile(id);
    dispatch(getProfileSuccess(profileGet.data));
  } catch (error) {
    dispatch(getProfileFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getProfileSuccess = (profileGet) => {
  return {
    type: profileUpdateActionTypes.GET_PROFILE_SUCCESS,
    payload: {
      profileGet: {
        ...profileGet,
        avatar: `${baseUrl}/public/images/avatars/${profileGet.id}.png`,
      }
    },
  }
}

const getProfileFailure = (error) => {
  return {
    type: profileUpdateActionTypes.GET_PROFILE_FAILURE,
    payload: {
      error: error
    }
  }
}


const updateAvatar = (profile) => async (dispatch) => {
  dispatch(updateAvatarBegin());
  try {
    const profileAvatarUpdate = await profileApi.updateAvatar(profile);
    let profileAvatar = JSON.parse(JSON.stringify(profileAvatarUpdate.data));
    dispatch(updateAvatarSuccess(profileAvatar));
  } catch (error) {
    dispatch(updateAvatarFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const updateAvatarBegin = () => {
  return {
    type: profileUpdateActionTypes.UPDATE_AVATAR
  }
}

const updateAvatarSuccess = (profileAvatar) => {
  return {
    type: profileUpdateActionTypes.UPDATE_AVATAR_SUCCESS,
    payload: {
      profileAvatar: {
        ...profileAvatar,
        avatar: `${baseUrl}/public/images/avatars/${profileAvatar.id}.png?t=${new Date().getTime()}`,
      }
    },
  }
}

const updateAvatarFailure = (error) => {
  return {
    type: profileUpdateActionTypes.UPDATE_AVATAR_FAILURE,
    payload: {
      error: error
    }
  }
}

const resetDefaultStatusAvatar = () => {
  return {
    type: profileUpdateActionTypes.UPDATE_AVATAR_STATUS_RESET
  }
}


const updateProfile = (profile) => async (dispatch) => {
  dispatch(updateProfileBegin());
  try {
    const profileUpdate = await profileApi.updateProfile(profile);
    let tempProfile = JSON.parse(JSON.stringify(profileUpdate.data));
    dispatch(updateProfileSuccess(tempProfile));
  } catch (error) {
    dispatch(updateProfileFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const updateProfileBegin = () => {
  return {
    type: profileUpdateActionTypes.UPDATE_PROFILE
  }
}

const updateProfileSuccess = (profileUpdate) => {
  return {
    type: profileUpdateActionTypes.UPDATE_PROFILE_SUCCESS,
    payload: {
      profileUpdate: {
        ...profileUpdate,
        avatar: `${baseUrl}/public/images/avatars/${profileUpdate.id}.png`,
      }
    },
  }
}

const updateProfileFailure = (error) => {
  return {
    type: profileUpdateActionTypes.UPDATE_PROFILE_FAILURE,
    payload: {
      error: error
    }
  }
}

const resetDefaultStatusProfile = () => {
  return {
    type: profileUpdateActionTypes.UPDATE_PROFILE_STATUS_RESET
  }
}

export default {
  getProfile,
  updateAvatar,
  updateProfile,
  resetDefaultStatusProfile,
  resetDefaultStatusAvatar
}
