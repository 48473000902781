import { sessionActionTypes } from '../actions';

const initialState = {
  loggedIn: false,
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    avatar: '',
    department: '',
    company: '',
    roles: []
  },
  error: '',
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionActionTypes.SESSION_LOGIN: {
      return {
        status: 'waiting'
      };
    }

    case sessionActionTypes.SESSION_LOGIN_SUCCESS: {
      return {
        ...action.payload,
        loggedIn: true,
        status: 'success'
      };
    }

    case sessionActionTypes.SESSION_LOGIN_FAILURE: {
      return {
        ...action.payload,
        loggedIn: false,
        status: 'error'
      };
    }

    case sessionActionTypes.SESSION_LOGOUT: {
      return {
        loggedIn: false,
        user: null,
        error: null,
      };
    }

    case sessionActionTypes.SESSION_GET_USER_INFO: {
      return {
        ...state,
        status: 'waiting'
      };
    }

    case sessionActionTypes.SESSION_GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        status: 'success'
      };
    }

    case sessionActionTypes.SESSION_GET_USER_INFO_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loggedIn: false,
        status: 'error'
      };
    }

    case sessionActionTypes.SESSION_RESET: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
