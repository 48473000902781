export const navBarActionTypes = {
  CHANGE_CURRENT_APP_ID: 'navBarAction/CHANGE_CURRENT_APP_ID',
}

//* only pure action
const changeCurrentAppId = (currentAppId) => {
  return {
    type: navBarActionTypes.CHANGE_CURRENT_APP_ID,
    payload: {
      currentAppId: currentAppId
    }
  }
}

export default {
  changeCurrentAppId
}
