import axios from 'utils/axios';

const getPermissionList = () => {
  return axios.get(`/permission-atms`);
}

const getFullPermissionList = () => {
  return axios.get(`/permission-atms/full/${true}`);
}

export default {
  getFullPermissionList,
  getPermissionList
};
