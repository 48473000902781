import axios from 'utils/axios';

const getAppPermissionList = (appId) => {
  return axios.get(`/apps/${appId}/permissions`);
}

const getFullAppPermissionList = (appId) => {
  return axios.get(`/apps/${appId}/permissions/full/${true}`);
}

export default {
  getAppPermissionList,
  getFullAppPermissionList
};
