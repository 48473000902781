import { profileUpdateActionTypes } from '../actions';

const initialState = {};

const profileUpdateReducer = (state = initialState, action) => {
  switch (action.type) {

    case profileUpdateActionTypes.GET_PROFILE: {
      return {}
    }

    case profileUpdateActionTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profileGet: action.payload.profileGet,
      }
    }

    case profileUpdateActionTypes.GET_PROFILE_FAILURE: {
      return {
        error: action.payload.error,
        status: 'error'
      }
    }


    case profileUpdateActionTypes.UPDATE_AVATAR: {
      return {
        ...state,
        status: 'waiting',
      }
    }

    case profileUpdateActionTypes.UPDATE_AVATAR_SUCCESS: {
      return {
        ...state,
        profileGet: action.payload.profileAvatar,
        status: 'success'
      }
    }

    case profileUpdateActionTypes.UPDATE_AVATAR_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case profileUpdateActionTypes.UPDATE_AVATAR_STATUS_RESET: {
      return {}
    }


    case profileUpdateActionTypes.UPDATE_PROFILE: {
      return {
        ...state,
        status: 'waiting',
      }
    }

    case profileUpdateActionTypes.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        profileGet: action.payload.profileUpdate,
        status: 'success'
      }
    }

    case profileUpdateActionTypes.UPDATE_PROFILE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case profileUpdateActionTypes.UPDATE_PROFILE_STATUS_RESET: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default profileUpdateReducer;
