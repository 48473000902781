import { userManageListActionTypes } from '../actions';

const initialState = {
  lstUser: [],
};

const userManageListReducer = (state = initialState, action) => {
  switch (action.type) {
    //* get list user
    case userManageListActionTypes.GET_USER_LIST: {
      return {
        ...state,
        getUserListStatus: 'waiting',
      };
    }

    case userManageListActionTypes.GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        lstUser: action.payload.lstUser,
        getUserListStatus: 'success',
        error: '',
      };
    }

    case userManageListActionTypes.GET_USER_LIST_FAILURE: {
      return {
        error: action.payload.error,
        lstUser: [],
        getUserListStatus: 'error'
      }
    }

    //* delete user
    case userManageListActionTypes.DELETE_USER: {
      return {
        ...state,
        deleteUserStatus: 'waiting',
      };
    }

    case userManageListActionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        userIdDeleted: action.payload.userIdDeleted,
        deleteUserStatus: 'success'
      };
    }

    case userManageListActionTypes.DELETE_USER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        deleteUserStatus: 'error'
      }
    }

    case userManageListActionTypes.RESET_MANAGE_USER_STATUS: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default userManageListReducer;
