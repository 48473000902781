import { changePasswordApi } from 'apis';
import { omit } from 'lodash';
import errorString from '../../utils/errorString';

export const changePasswordActionTypes = {
  CHANGE_PASSWORD: 'changePasswordAction/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'changePasswordAction/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'changePasswordAction/CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_RESET: 'changePasswordAction/CHANGE_PASSWORD_RESET'
}

//* thunk action
const changePassword = (values, userId) => async dispatch => {
  //* notify begin action to component
  const valuesPassword = {
    ...omit(values, ['confirm', 'newPassword']),
    password: values.newPassword
  }
  dispatch(changePasswordBegin());
  try {
    // eslint-disable-next-line
    await changePasswordApi.postChangePassword(valuesPassword, userId);
  
    dispatch(changePasswordSuccess());
  } catch (error) {
    if (typeof error.response.data.message === 'string') {
      dispatch(changePasswordFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    } else {
      dispatch(changePasswordFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.matches))));
    }
  }
}

//* only pure action
const changePasswordBegin = () => {
  return {
    type: changePasswordActionTypes.CHANGE_PASSWORD
  }
}

const changePasswordSuccess = () => {
  return {
    type: changePasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
  }
}

const changePasswordFailure = (error) => {
  return {
    type: changePasswordActionTypes.CHANGE_PASSWORD_FAILURE,
    payload: {
      error: error
    },
  }
}

const changePasswordReset = () => {
  return {
    type: changePasswordActionTypes.CHANGE_PASSWORD_RESET,
  }
}

export default {
  changePassword,
  changePasswordReset
}
