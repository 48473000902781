import { appManageListActionTypes } from '../actions';

const initialState = {
  app:{
    name: '',
  }
};

const appManageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case appManageListActionTypes.GET_APP_LIST: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appManageListActionTypes.GET_APP_LIST_SUCCESS: {
      return {
        ...state,
        appsList: action.payload.appsList.data,
        status: 'success'
      };
    }

    case appManageListActionTypes.GET_APP_LIST_FAILURE: {
      return {
        ...state,
        appsList: [],
        error: action.payload.error,
        status: 'error'
      }
    }

    //*Delete app
    case appManageListActionTypes.DELETE_APP: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appManageListActionTypes.DELETE_APP_SUCCESS: {
      state.appsList = state.appsList.filter(app => {
        return (app.id !== action.payload.app.data.id)
      });
      return {
        ...state,
        status: 'delete success'
      };
    }

    case appManageListActionTypes.DELETE_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case appManageListActionTypes.DELETE_APP_RESET: {
      return {
        ...state,
        status: ''
      }
    }
    //*Create app
    case appManageListActionTypes.CREATE_APP: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appManageListActionTypes.CREATE_APP_SUCCESS: {
      state.appsList.unshift(action.payload.app.data)
      return {
        ...state,
        status: 'create success'
      };
    }

    case appManageListActionTypes.CREATE_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case appManageListActionTypes.CREATE_APP_RESET: {
      return {
        ...state,
        status: ''
      }
    }

    //*Update app
    case appManageListActionTypes.UPDATE_APP: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appManageListActionTypes.UPDATE_APP_SUCCESS: {
      if(action.payload.app.data !== 'Error'){
        state.appsList = state.appsList.map(app => {
          if(app.id === action.payload.app.data.id){
            app.name = action.payload.app.data.name
            app.description = action.payload.app.data.description
            app.url = action.payload.app.data.url
            app.status = action.payload.app.data.status
          }
          return app;
        })
        return {
          ...state,
          status: 'update success'
        };
      }
      else{
        return {
          ...state,
          status: 'error'
        };
      }
    }

    case appManageListActionTypes.UPDATE_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case appManageListActionTypes.SET_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        currentApp: action.payload.currentApp,
        statusSetCurrentApp: 'success'
      }
    }

    case appManageListActionTypes.SET_CURRENT_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      }
    }

    case appManageListActionTypes.RESET_STATUS_CURRENT_APP_FAILURE: {
      return {
        ...state,
        statusSetCurrentApp: undefined
      }
    }
    //*get app by Id
    case appManageListActionTypes.GET_APP_BY_ID: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appManageListActionTypes.GET_APP_BY_ID_SUCCESS: {
      return {
        ...state,
        app: action.payload.app.data,
        status: 'create success'
      };
    }

    case appManageListActionTypes.GET_APP_BY_ID_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case appManageListActionTypes.RESET_MANAGE_APP: {
      return initialState
    }
    
    default: {
      return state;
    }
  }
};

export default appManageListReducer;
