import axios from 'utils/axios';

const getAppsList = () => {
  return axios.get(`/apps`);
}

const deleteApp = (id) => {
  return axios.delete(`/apps/${id}`);
}

const createApp = (app) => {
  return axios.post(`/apps`, app);
}

const updateApp = (app) => {
  return axios.patch(`/apps/${app.id}`, app);
}

const getAppById = (id) => {
  return axios.get(`/apps/${id}`);
}

const getListUserInApp = (appId) => {
  return axios.get(`/apps/${appId}/users`);
}

const getPermissionOfUser = (userId,appId) => {
  return axios.get(`/apps/${appId}/users/${userId}/permissions`);
}

const getRoleOfUser = (userId,appId) => {
  return axios.get(`/apps/${appId}/users/${userId}/roles`);
}

const getPermissionList = (appId) => {
  return axios.get(`/apps/${appId}/permissions`);
}

const getRoleList = (appId) => {
  return axios.get(`/apps/${appId}/roles`);
}

const updateUserStatus = (userId,appId,status) => {
  return axios.patch(`/apps/${appId}/users/${userId}/app-of-user`,{status})
}

const updatePermissionInAppOfUser = (permissionIds,userId, appId) => {
  return axios.patch(`/permissions/apps/${appId}/users/${userId}`, permissionIds);
}

const updateRoleInAppOfUser = (roleIds,appId,userId) => {
  return axios.patch(`/roles/apps/${appId}/users/${userId}`,{roleIds});
}

const getUserToAdd = (appId) => {
  return axios.get(`/apps/${appId}/not-users`);
}

const addUserInApp = (userIds, appId) => {
  return axios.post(`/apps/${appId}/users`, {userIds});
}

const deleteUserInApp = (userId,appId) => {
  return axios.delete(`/apps/${appId}/users/${userId}`);
}

const getCurrentApp = (id) => {
  return axios.get(`/apps/${id}`);
}


export default {
  getAppsList,
  deleteApp,
  createApp,
  updateApp,
  getListUserInApp,
  getPermissionList,
  getRoleList,
  getRoleOfUser,
  getPermissionOfUser,
  updatePermissionInAppOfUser,
  getUserToAdd,
  addUserInApp,
  deleteUserInApp,
  getCurrentApp,
  getAppById,
  updateRoleInAppOfUser,
  updateUserStatus
}
