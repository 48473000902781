import { appUserListActionTypes } from '../actions';

const initialState = {
  permissionList:[],
  roleList:[],
  permission:[],
  role:[],
  userToAdd:[],
  listUser:[],
  updated: false
};

const appUserReducer = (state = initialState, action) => {
  switch (action.type) {
    //* get list user
    case appUserListActionTypes.GET_USER_IN_APP_LIST: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appUserListActionTypes.GET_USER_IN_APP_LIST_SUCCESS: {
      return {
        ...state,
        listUser: action.payload.listUser.data,
        status: 'success',
        updated: false
      };
    }

    case appUserListActionTypes.GET_USER_IN_APP_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        listUser: [],
        status: 'error'
      }
    }
    
    //* update user
    case appUserListActionTypes.UPDATE_USER_IN_APP: {
      return {
        ...state,
        status: 'waiting',
        updated:false
      };
    }

    case appUserListActionTypes.UPDATE_USER_IN_APP_SUCCESS: {
      state.listUser = state.listUser.map(item => {
        if(item.user.id === action.payload.appOfUserStatus.data.user.id){
          item.status = action.payload.appOfUserStatus.data.status
        }
        return item;
      })
      return {
        ...state,
        status: 'update user success',
        permission: [],
        role: [],
        updated:true
      };
    }

    case appUserListActionTypes.UPDATE_USER_IN_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case appUserListActionTypes.UPDATE_USER_IN_APP_RESET: {
      return {
        ...state,
        permission: [],
        role: [],
        permissionList: [],
        roleList: [],
        status:''
      }
    } 

    //* get user to add
    case appUserListActionTypes.GET_USER_TO_ADD: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appUserListActionTypes.GET_USER_TO_ADD_SUCCESS: {
      return {
        ...state,
        userToAdd: action.payload.userToAdd.data,
        status: 'get user to add success'
      };
    }

    case appUserListActionTypes.GET_USER_TO_ADD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    //* add user in app
    case appUserListActionTypes.ADD_USER_IN_APP: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appUserListActionTypes.ADD_USER_IN_APP_SUCCESS: {
      const list = [...state.listUser];
      const newUsers = action.payload.listUser.data.map((item => {
        const newUser = {
          user: item,
          status: 'active'
        }
        return newUser;
      }))
      newUsers.forEach(item => {
        list.unshift(item);
      })
      return {
        ...state,
        listUser: list,
        status: 'add user success',
        addStatus: 'success',
        updated:true

      };
    }

    case appUserListActionTypes.ADD_USER_IN_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error',
        updated:false

      }
    }

    //* delete user in app
    case appUserListActionTypes.DELETE_USER_IN_APP: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case appUserListActionTypes.DELETE_USER_IN_APP_SUCCESS: {
      state.listUser = state.listUser.filter(item => {
        return (item.user.id !== action.payload.userOfApp.data.user.id)
      })
      return {
        ...state,
        status: 'delete user success'
      };
    }

    case appUserListActionTypes.DELETE_USER_IN_APP_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    //* get info to update
    case appUserListActionTypes.GET_INFO_TO_UPDATE: {
      return {
        ...state,
        status: 'waiting',
        error: 'waiting',
      };
    }

    case appUserListActionTypes.GET_INFO_TO_UPDATE_SUCCESS: {
      return {
        ...state,
        permissionList: action.payload.values[0].data,
        roleList: action.payload.values[1].data,
        permission: action.payload.values[2].data,
        role: action.payload.values[3].data,
        status: 'get info success',
        error: '',
      };
    }

    case appUserListActionTypes.GET_INFO_TO_UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }
    //* resetStatus
    case appUserListActionTypes.RESET_STATUS:{
      return {
        ...state,
        status: ''
      }
    }

    case appUserListActionTypes.RESET_MANAGE_USER_IN_APP:{
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default appUserReducer;
