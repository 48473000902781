import { roleManageListActionTypes } from '../actions';

const initialState = {
  roleList: [],
  permissionList: []
};

const roleManageListReducer = (state = initialState, action) => {
  switch (action.type) {
    //* get role list
    case roleManageListActionTypes.GET_ROLE_LIST: {
      return {
        getRoleListStatus: 'waiting',
      };
    }

    case roleManageListActionTypes.GET_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        roleList: action.payload.roleList,
        getRoleListStatus: 'success'
      };
    }

    case roleManageListActionTypes.GET_ROLE_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        roleList: [],
        getRoleListStatus: 'error'
      }
    }

    //* get permission list
    case roleManageListActionTypes.GET_PERMISSION_LIST_SUCCESS: {
      return {
        ...state,
        permissionList: action.payload.permissionList,
        getPermissionListStatus: 'success'
      };
    }

    case roleManageListActionTypes.GET_PERMISSION_LIST_FAILURE: {
      return {
        ...state,
        getPermissionError: action.payload.error,
        getPermissionListStatus: 'error'
      }
    }

    //* post create role
    case roleManageListActionTypes.CREATE_ROLE_SUCCESS: {
      return {
        ...state,

        roleCreateInfor: action.payload.roleCreateInfor,
        createRoleStatus: 'success'
      };
    }

    case roleManageListActionTypes.CREATE_ROLE_FAILURE: {
      return {
        ...state,
        createRoleError: action.payload.error,
        createRoleStatus: 'error'
      }
    }

    //* reset create role
    case roleManageListActionTypes.RESET_CREATE_ROLE: {
      return {
        ...state,
        roleCreateInfor: undefined,
        createRoleStatus: undefined,
        createRoleError: undefined,
        roleCreate: undefined,
        permissionList: []
      }
    }

    //* get role where id
    case roleManageListActionTypes.GET_UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleGetUpdate: action.payload.roleGetUpdate,
        getUpdateRoleStatus: 'success'
      };
    }

    case roleManageListActionTypes.GET_UPDATE_ROLE_FAILURE: {
      return {
        ...state,
        getUpdateRoleError: action.payload.error,
        getUpdateRoleStatus: 'error'
      }
    }

    //* post role update
    case roleManageListActionTypes.POST_UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        postUpdateRoleStatus: 'success'
      };
    }

    case roleManageListActionTypes.POST_UPDATE_ROLE_FAILURE: {
      return {
        ...state,
        postUpdateRoleError: action.payload.error,
        postUpdateRoleStatus: 'error'
      }
    }

    //* reset update role
    case roleManageListActionTypes.RESET_UPDATE_ROLE: {
      return {
        ...state,
        getUpdateRoleStatus: undefined,
        postUpdateRoleStatus: undefined,
        postUpdateRoleError: undefined,
        roleGetUpdate: undefined,
        permissionList: []
      }
    }

    //* delete role where id
    case roleManageListActionTypes.DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        roleDeletedId: action.payload.roleDeletedId,
        deleteRoleStatus: 'success'
      };
    }

    case roleManageListActionTypes.DELETE_ROLE_FAILURE: {
      return {
        ...state,
        deleteRoleError: action.payload.error,
        deleteRoleStatus: 'error'
      }
    }

    case roleManageListActionTypes.DELETE_ROLE_STATUS_RESET: {
      return {
        ...state,
        roleDeletedId: undefined,
        deleteRoleStatus: undefined,
      }
    }

    //* reset role status
    case roleManageListActionTypes.ROLE_STATUS_RESET: {
      return initialState
    }

    //* return default
    default: {
      return state;
    }
  }
};

export default roleManageListReducer;
