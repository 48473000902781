// eslint-disable-next-line
import { usersApi } from 'apis';
import compareArray from 'utils/compareArray';
import errorString from '../../utils/errorString';

export const userUpdateActionTypes = {
  GET_USER: 'userUpdate/GET_USER',
  GET_USER_SUCCESS: 'userUpdate/GET_USER_SUCCESS',
  GET_USER_FAILURE: 'userUpdate/GET_USER_FAILURE',

  GET_PERMISSION_ATM_LIST: 'userUpdate/GET_PERMISSION_ATM_LIST',
  GET_PERMISSION_ATM_LIST_SUCCESS: 'userUpdate/GET_PERMISSION_ATM_LIST_SUCCESS',
  GET_PERMISSION_ATM_LIST_FAILURE: 'userUpdate/GET_PERMISSION_ATM_LIST_FAILURE',

  GET_ROLE_ATM_LIST: 'userUpdate/GET_ROLE_ATM_LIST',
  GET_ROLE_ATM_LIST_SUCCESS: 'userUpdate/GET_ROLE_ATM_LIST_SUCCESS',
  GET_ROLE_ATM_LIST_FAILURE: 'userUpdate/GET_ROLE_ATM_LIST_FAILURE',

  UPDATE_USER: 'userUpdate/UPDATE_USER',
  UPDATE_USER_SUCCESS: 'userUpdate/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'userUpdate/UPDATE_USER_FAILURE',
  RESET_UPDATE_USER_STATUS: 'userUpdate/RESET_UPDATE_USER_STATUS',
  RESET_UPDATE_USER: 'userUpdate/RESET_UPDATE_USER',
}

//* get user
const getUser = (id) => async (dispatch) => {
  dispatch(getUserBegin());

  try {
    const [user, lstRoleAtm, lstPermissionAtm] = await Promise.all([usersApi.getUser(id), usersApi.getRoleAtmsOfUser(id), usersApi.getPermissionAtmsOfUser(id)]);
    
    const userMix = {
      ...user.data,
      lstRoleAtm: [ ...lstRoleAtm.data ],
      lstPermissionAtm: [ ...lstPermissionAtm.data ]
    }
    
    dispatch(getUserSuccess(userMix));
  } catch (error) {
    dispatch(getUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getUserBegin = () => {
  return {
    type: userUpdateActionTypes.GET_USER
  }
}

const getUserSuccess = (user) => {
  return {
    type: userUpdateActionTypes.GET_USER_SUCCESS,
    payload: {
      user
    }
  }
}

const getUserFailure = (error) => {
  return {
    type: userUpdateActionTypes.GET_USER_FAILURE,
    payload: {
      error
    }
  }
}

//* get role list
const getRoleAtmList = () => async (dispatch) => {
  dispatch(getRoleAtmListBegin());

  try {
    // const lstRoleAtm = await rolesAtmApi.getRoleList();
    const response = await usersApi.getRoleAtmList();

    const lstRoleAtm = [
      ...response.data
    ]

    dispatch(getRoleAtmListSuccess(lstRoleAtm));
  } catch (error) {
    dispatch(getRoleAtmListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getRoleAtmListBegin = () => {
  return {
    type: userUpdateActionTypes.GET_ROLE_ATM_LIST,
  }
}

const getRoleAtmListSuccess = (lstRoleAtm) => {
  return {
    type: userUpdateActionTypes.GET_ROLE_ATM_LIST_SUCCESS,
    payload: {
      lstRoleAtm
    }
  }
}

const getRoleAtmListFailure = (error) => {
  return {
    type: userUpdateActionTypes.GET_ROLE_ATM_LIST_FAILURE,
    payload: {
      error
    }
  }
}

//* get permission list
const getPermissionAtmList = () => async (dispatch) => {
  dispatch(getPermissionAtmListBegin());

  try {
    // const lstPermissionAtm = await permissionsAtmApi.getPermissionList();
    const response = await usersApi.getPermissionAtmList();

    const lstPermissionAtm = [
      ...response.data
    ]

    dispatch(getPermissionAtmListSuccess(lstPermissionAtm));
  } catch (error) {
    dispatch(getPermissionAtmListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getPermissionAtmListBegin = () => {
  return {
    type: userUpdateActionTypes.GET_PERMISSION_ATM_LIST,
  }
}

const getPermissionAtmListSuccess = (lstPermissionAtm) => {
  return {
    type: userUpdateActionTypes.GET_PERMISSION_ATM_LIST_SUCCESS,
    payload: {
      lstPermissionAtm
    }
  }
}

const getPermissionAtmListFailure = (error) => {
  return {
    type: userUpdateActionTypes.GET_PERMISSION_ATM_LIST_FAILURE,
    payload: {
      error
    }
  }
}

//* update user
// const updateUser = (user, lstRoleAtmId, lstPermissionAtmId) => async (dispatch) => {
//   dispatch(updateUserBegin());
//   try {
//     const userNewResponse = await usersApi.updateUser(user);
//     const userNew = {
//       ...userNewResponse.data
//     }
    
//     const listRoleAtmOfUser = { listRoleAtmId: [...lstRoleAtmId] }
//     const listRoleAtmOfUserResponse = await usersApi.updateRoleAtmInUser(user.id, listRoleAtmOfUser);
//     const lstRoleAtmNew = [
//       ...listRoleAtmOfUserResponse.data
//     ]

//     const listPermissionAtmOfUser = { listPermissionAtmId: [...lstPermissionAtmId] }
//     const listPermissionAtmOfUserResponse = await usersApi.updatePermissionAtmInUser(user.id, listPermissionAtmOfUser);
//     const lstPermissionAtmNew = [
//       ...listPermissionAtmOfUserResponse.data
//     ]

//     const userMix = {
//       ...userNew,
//       lstRoleAtm: [ ...lstRoleAtmNew ],
//       lstPermissionAtm: [ ...lstPermissionAtmNew ]
//     }

//     console.log(userMix);

//     dispatch(updateUserSuccess(userMix));
//   } catch (error) {
//     if (typeof error.response.data.message === 'string') {
//       dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
//     } else {
//       dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.matches))));
//     }
//   }
// }

//* handle update speed
const updateUser = (user, lstRoleAtmId, lstPermissionAtmId, oldListPermissionAtmOfUserId) => async (dispatch) => {
  dispatch(updateUserBegin());
  try {
    const userNewResponse = await usersApi.updateUser(user);
    const userNew = {
      ...userNewResponse.data
    }
    
    const listRoleAtmOfUser = { listRoleAtmId: [...lstRoleAtmId] }
    const listRoleAtmOfUserResponse = await usersApi.updateRoleAtmInUser(user.id, listRoleAtmOfUser);
    const lstRoleAtmNew = [
      ...listRoleAtmOfUserResponse.data
    ]

    const listPermissionAtmOfUserCompareId = compareArray(oldListPermissionAtmOfUserId, lstPermissionAtmId);
    const listPermissionAtmOfUserUpdateId = {
      listPermissionAtmOfUserDeleteId: listPermissionAtmOfUserCompareId.listItemDelete,
      listPermissionAtmOfUserAddId: listPermissionAtmOfUserCompareId.listItemAdd
    }

    const listPermissionAtmOfUserResponse = await usersApi.updatePermissionAtmInUser(user.id, listPermissionAtmOfUserUpdateId);
    const lstPermissionAtmNew = [
      ...listPermissionAtmOfUserResponse.data
    ]

    const userMix = {
      ...userNew,
      lstRoleAtm: [ ...lstRoleAtmNew ],
      lstPermissionAtm: [ ...lstPermissionAtmNew ]
    }

    dispatch(updateUserSuccess(userMix));
  } catch (error) {
    if (typeof error.response.data.message === 'string') {
      dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
    } else {
      dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message[0].constraints.matches))));
    }
  }
}

const updateUserBegin = () => {
  return {
    type: userUpdateActionTypes.UPDATE_USER,
  }
}

const updateUserSuccess = (user) => {
  return {
    type: userUpdateActionTypes.UPDATE_USER_SUCCESS,
    payload: {
      user,
    }
  }
}

const updateUserFailure = (error) => {
  return {
    type: userUpdateActionTypes.UPDATE_USER_FAILURE,
    payload: {
      error
    }
  }
}

const resetUpdateUserStatus = (error) => {
  return {
    type: userUpdateActionTypes.RESET_UPDATE_USER_STATUS,
  }
}

const resetUpdateUser = () => {
  return {
    type: userUpdateActionTypes.RESET_UPDATE_USER,
  }
}

export default {
  getUser,
  getRoleAtmList,
  getPermissionAtmList,
  updateUser,
  resetUpdateUserStatus,
  resetUpdateUser,
}
