import { permissionsAtmApi } from 'apis';
import errorString from '../../utils/errorString';

export const permissionActionTypes = {
  GET_PERMISSIONS: 'permissionAction/GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'permissionAction/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILURE: 'permissionAction/GET_PERMISSIONS_FAILURE',
  GET_PERMISSIONS_STATUS_RESET: 'permissionAction/GET_PERMISSIONS_STATUS_RESET',
}

//* thunk action
//* call api side effect
const getPermissions = () => async (dispatch) => {
  dispatch(getPermissionsBegin());
  try {
    const permission = await permissionsAtmApi.getFullPermissionList();
    dispatch(getPermissionsSuccess(permission));
  } catch (error) {
    dispatch(getPermissionsFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

//* only pure action
const getPermissionsBegin = () => {
  return {
    type: permissionActionTypes.GET_PERMISSIONS
  }
}

const getPermissionsSuccess = (permission) => {
  return {
    type: permissionActionTypes.GET_PERMISSIONS_SUCCESS,
    payload: {
      permission: permission
    },
  }
}

const getPermissionsFailure = (error) => {
  return {
    type: permissionActionTypes.GET_PERMISSIONS_FAILURE,
    payload: {
      error: error
    }
  }
}

const getPermissionsResetStatus = () => {
  return {
    type: permissionActionTypes.GET_PERMISSIONS_STATUS_RESET
  }
}

export default {
  getPermissions,
  getPermissionsResetStatus,
}
