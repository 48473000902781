import { appsApi } from 'apis';
import compareArray from 'utils/compareArray';
import errorString from '../../utils/errorString';

export const appUserListActionTypes = {
  GET_USER_IN_APP_LIST: 'appUserAction/GET_USER_IN_APP_LIST',
  GET_USER_IN_APP_LIST_SUCCESS: 'appUserAction/GET_USER_IN_APP_LIST_SUCCESS',
  GET_USER_IN_APP_LIST_FAILURE: 'appUserAction/GET_USER_IN_APP_LIST_FAILURE',
  GET_PERMISSION_LIST: 'appUserAction/GET_PERMISSION_LIST',
  GET_PERMISSION_LIST_SUCCESS: 'appUserAction/GET_PERMISSION_LIST_SUCCESS',
  GET_PERMISSION_LIST_FAILURE: 'appUserAction/GET_PERMISSION_LIST_FAILURE',
  GET_ROLE_LIST: 'appUserAction/GET_ROLE_LIST',
  GET_ROLE_LIST_SUCCESS: 'appUserAction/GET_ROLE_LIST_SUCCESS',
  GET_ROLE_LIST_FAILURE: 'appUserAction/GET_ROLE_LIST_FAILURE',
  GET_PERMISSION_OF_USER: 'appUserAction/GET_PERMISSION_OF_USER',
  GET_PERMISSION_OF_USER_SUCCESS: 'appUserAction/GET_PERMISSION_OF_USER_SUCCESS',
  GET_PERMISSION_OF_USER_FAILURE: 'appUserAction/GET_PERMISSION_OF_USER_FAILURE',
  GET_ROLE_OF_USER: 'appUserAction/GET_ROLE_OF_USER',
  GET_ROLE_OF_USER_SUCCESS:'appUserAction/GET_ROLE_OF_USER_SUCCESS',
  GET_ROLE_OF_USER_FAILURE:'appUserAction/GET_ROLE_OF_USER_FAILURE',
  UPDATE_USER_IN_APP: 'appUserAction/UPDATE_USER_IN_APP',
  UPDATE_USER_IN_APP_SUCCESS:'appUserAction/UPDATE_USER_IN_APP_SUCCESS',
  UPDATE_USER_IN_APP_FAILURE:'appUserAction/UPDATE_USER_IN_APP_FAILURE',
  UPDATE_USER_IN_APP_RESET:'UPDATE_USER_IN_APP_RESET',
  GET_USER_TO_ADD:'appUserAction/GET_USER_TO_ADD',
  GET_USER_TO_ADD_SUCCESS:'appUserAction/GET_USER_TO_ADD_SUCCESS',
  GET_USER_TO_ADD_FAILURE:'appUserAction/GET_USER_TO_ADD_FAILURE',
  ADD_USER_IN_APP:'appUserAction/ADD_USER_IN_APP',
  ADD_USER_IN_APP_SUCCESS:'appUserAction/ADD_USER_IN_APP_SUCCESS',
  ADD_USER_IN_APP_FAILURE:'appUserAction/ADD_USER_IN_APP_FAILURE',
  DELETE_USER_IN_APP:'appUserAction/DELETE_USER_IN_APP',
  DELETE_USER_IN_APP_SUCCESS:'appUserAction/DELETE_USER_IN_APP_SUCCESS',
  DELETE_USER_IN_APP_FAILURE: 'appUserAction/DELETE_USER_IN_APP_FAILURE',
  UPDATE_USER_STATUS: 'appUserAction/UPDATE_USER_STATUS',
  UPDATE_USER_STATUS_SUCCESS: 'appUserAction/UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_FAILURE: 'appUserAction/UPDATE_USER_STATUS_FAILURE',
  RESET_STATUS: 'appUserAction/RESET_STATUS',
  GET_INFO_TO_UPDATE: 'appUserAction/GET_INFO_TO_UPDATE',
  GET_INFO_TO_UPDATE_SUCCESS: 'appUserAction/GET_INFO_TO_UPDATE_SUCCESS',
  GET_INFO_TO_UPDATE_FAILURE: 'appUserAction/GET_INFO_TO_UPDATE_FAILURE',
  GET_INFO_TO_UPDATE_RESET: 'appUserAction/GET_INFO_TO_UPDATE_RESET',
  RESET_MANAGE_USER_IN_APP: 'appUserAction/RESET_MANAGE_USER_IN_APP',

}

const getUserInAppList = (appId) => async dispatch => {
  //* notify begin action to component
  dispatch(getUserInAppListBegin());

  try {
    const userList = await appsApi.getListUserInApp(appId);
    dispatch(getUserInAppListSuccess(userList));
  } catch (error) {
    dispatch(getUserInAppListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getInfoToUpdate = (userId,appId) => async dispatch => {
  //* notify begin action to component
  dispatch(getInfoToUpdateBegin());
  try {
    const permissionList =  appsApi.getPermissionList(appId);
    const roleList =  appsApi.getRoleList(appId);
    const permission =  appsApi.getPermissionOfUser(userId,appId);
    const role =  appsApi.getRoleOfUser(userId,appId);
    const values = await Promise.all([permissionList,roleList,permission,role])
    dispatch(getInfoToUpdateSuccess(values))
  } catch (error){
    dispatch(getInfoToUpdateFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))))
  }
}

// const updateUser = (permissionIds,roleIds,userId,appId,status) => async dispatch => {
//   //* notify begin action to component
//   dispatch(updateUserBegin());
//   try {
//     const userUpdatedPermission =  appsApi.updatePermissionInAppOfUser(permissionIds,userId,appId);
//     const userUpdatedRole =  appsApi.updateRoleInAppOfUser(roleIds,userId,appId);
//     const appOfUserStatus =  appsApi.updateUserStatus(userId,appId,status);
//     const valuesUpdate = await Promise.all([userUpdatedPermission,userUpdatedRole,appOfUserStatus])
//     dispatch(updateUserSuccess(valuesUpdate));
//   } catch (error) {
//     dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
//   }
// }

//* handle update speed
const updateUser = (oldPermissionIds,permissionIds,roleIds,userId,appId,status) => async dispatch => {
  //* notify begin action to component
  dispatch(updateUserBegin());
  
  const listPermissionCompareId = compareArray(oldPermissionIds, permissionIds);

  const listPermissionUpdateId = {
    listPermissionInAppOfUserDeleteId: listPermissionCompareId.listItemDelete,
    listPermissionInAppOfUserAddId: listPermissionCompareId.listItemAdd
  }

  try {
    const userUpdatedPermission =  appsApi.updatePermissionInAppOfUser(listPermissionUpdateId,userId,appId);
    const userUpdatedRole =  appsApi.updateRoleInAppOfUser(roleIds,userId,appId);
    const appOfUserStatus =  appsApi.updateUserStatus(userId,appId,status);
    const valuesUpdate = await Promise.all([userUpdatedPermission,userUpdatedRole,appOfUserStatus])
    dispatch(updateUserSuccess(valuesUpdate));
  } catch (error) {
    dispatch(updateUserFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getUserToAdd = (appId) => async dispatch => {
  //* notify begin action to component
  dispatch(getUserToAddBegin());
  try {
    const userToAdd = await appsApi.getUserToAdd(appId);
    dispatch(getUserToAddSuccess(userToAdd));
  } catch (error) {
    dispatch(getUserToAddFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}
const resetUserUpdate = () => async dispatch => {
  dispatch(updateUserReset());
}

const addUser = (userId, appId) => async dispatch => {
  //* notify begin action to component
  dispatch(addUserInAppBegin());
  try {
    const listUser = await appsApi.addUserInApp(userId,appId);
    dispatch(addUserInAppSuccess(listUser));
  } catch (error) {
    dispatch(addUserInAppFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const deleteUser = (userId, appId) => async dispatch => {
  //* notify begin action to component
  dispatch(deleteUserInAppBegin());
  try {
    const userOfApp = await appsApi.deleteUserInApp(userId,appId);
    dispatch(deleteUserInAppSuccess(userOfApp));
  } catch (error) {
    dispatch(deleteUserInAppFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const resetStatus = () => dispatch => {
  dispatch({
    type: appUserListActionTypes.RESET_STATUS
  })
}


//*=======================================================================
const getInfoToUpdateBegin = () => {
  return {
    type: appUserListActionTypes.GET_INFO_TO_UPDATE
  }
}

const getInfoToUpdateSuccess = (values) => {
  return {
    type: appUserListActionTypes.GET_INFO_TO_UPDATE_SUCCESS,
    payload: {
      values: values
    },
  }
}

const getInfoToUpdateFailure = (error) => {
  return {
    type: appUserListActionTypes.GET_INFO_TO_UPDATE_FAILURE,
    payload: {
      error: error
    },
  }
}


const getUserInAppListBegin = () => {
  return {
    type: appUserListActionTypes.GET_USER_IN_APP_LIST
  }
}

const getUserInAppListSuccess = (listUser) => {
  return {
    type: appUserListActionTypes.GET_USER_IN_APP_LIST_SUCCESS,
    payload: {
      listUser: listUser
    },
  }
}

const getUserInAppListFailure = (error) => {
  return {
    type: appUserListActionTypes.GET_USER_IN_APP_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

const updateUserBegin = () => {
  return {
    type: appUserListActionTypes.UPDATE_USER_IN_APP
  }
}

const updateUserSuccess = (valuesUpdate) => {
  return {
    type: appUserListActionTypes.UPDATE_USER_IN_APP_SUCCESS,
    payload: {
      appOfUserStatus: valuesUpdate[2]
    },
  }
}

const updateUserFailure = (error) => {
  return {
    type: appUserListActionTypes.UPDATE_USER_IN_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

const updateUserReset = () => {
  return{
    type: appUserListActionTypes.UPDATE_USER_IN_APP_RESET,
  }
}

const getUserToAddBegin = () => {
  return {
    type: appUserListActionTypes.GET_USER_TO_ADD
  }
}

const getUserToAddSuccess = (userToAdd) => {
  return {
    type: appUserListActionTypes.GET_USER_TO_ADD_SUCCESS,
    payload: {
      userToAdd: userToAdd
    },
  }
}

const getUserToAddFailure = (error) => {
  return {
    type: appUserListActionTypes.GET_USER_TO_ADD_FAILURE,
    payload: {
      error: error
    },
  }
}

const addUserInAppBegin = () => {
  return {
    type: appUserListActionTypes.ADD_USER_IN_APP
  }
}

const addUserInAppSuccess = (listUser) => {
  return {
    type: appUserListActionTypes.ADD_USER_IN_APP_SUCCESS,
    payload: {
      listUser: listUser
    },
  }
}

const addUserInAppFailure = (error) => {
  return {
    type: appUserListActionTypes.ADD_USER_IN_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

const deleteUserInAppBegin = () => {
  return {
    type: appUserListActionTypes.DELETE_USER_IN_APP
  }
}

const deleteUserInAppSuccess = (userOfApp) => {
  return {
    type: appUserListActionTypes.DELETE_USER_IN_APP_SUCCESS,
    payload: {
      userOfApp: userOfApp
    },
  }
}

const deleteUserInAppFailure = (error) => {
  return {
    type: appUserListActionTypes.DELETE_USER_IN_APP_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetManageUserInApp = () => {
  return {
    type: appUserListActionTypes.RESET_MANAGE_USER_IN_APP,
  }
}

export default {
  getUserInAppList,
  updateUser,
  getUserToAdd,
  addUser,
  deleteUser,
  resetUserUpdate,
  resetStatus,
  getInfoToUpdate,
  resetManageUserInApp,
}
