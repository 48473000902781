import { resetPasswordActionTypes } from '../actions';

const initialState = {};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case resetPasswordActionTypes.RESET_PASSWORD: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case resetPasswordActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resultResetPassword: action.payload.resultResetPassword,
        status: 'reset success'
      };
    }

    case resetPasswordActionTypes.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'reset error'
      }
    }

    case resetPasswordActionTypes.RESET_PASSWORD_RESET: {
      return {
        ...state,
        error: '',
        resultResetPassword:''
      }
    }
    //*Check Token
    case resetPasswordActionTypes.CHECK_TOKEN: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case resetPasswordActionTypes.CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        resultCheckToken: action.payload.result,
        status: 'check token success'
      };
    }

    case resetPasswordActionTypes.CHECK_TOKEN_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }
    case resetPasswordActionTypes.CHECK_TOKEN_RESET: {
      return {
        ...state,
        error: '',
        resultCheckToken:''
      }
    }

    default: {
      return state;
    }
  }
};

export default resetPasswordReducer;
