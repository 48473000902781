import { rolesAtmApi } from 'apis';
import errorString from '../../utils/errorString';
import compareArray from 'utils/compareArray';

export const roleManageListActionTypes = {
  GET_ROLE_LIST: 'roleManageListAction/GET_ROLE_LIST',
  GET_ROLE_LIST_SUCCESS: 'roleManageListAction/GET_ROLE_LIST_SUCCESS',
  GET_ROLE_LIST_FAILURE: 'roleManageListAction/GET_ROLE_LIST_FAILURE',

  GET_PERMISSION_LIST_SUCCESS: 'roleManageListAction/GET_PERMISSION_LIST_SUCCESS',
  GET_PERMISSION_LIST_FAILURE: 'roleManageListAction/GET_PERMISSION_LIST_FAILURE',

  CREATE_ROLE_SUCCESS: 'roleManageListAction/CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'roleManageListAction/CREATE_ROLE_FAILURE',
  RESET_CREATE_ROLE: 'roleManageListAction/RESET_CREATE_ROLE',

  GET_UPDATE_ROLE_SUCCESS: 'roleManageListAction/GET_UPDATE_ROLE_SUCCESS',
  GET_UPDATE_ROLE_FAILURE: 'roleManageListAction/GET_UPDATE_ROLE_FAILURE',
  GET_UPDATE_ROLE_STATUS_RESET: 'roleManageListAction/GET_UPDATE_ROLE_STATUS_RESET',
  POST_UPDATE_ROLE_SUCCESS: 'roleManageListAction/POST_UPDATE_ROLE_SUCCESS',
  POST_UPDATE_ROLE_FAILURE: 'roleManageListAction/POST_UPDATE_ROLE_FAILURE',
  POST_UPDATE_ROLE_STATUS_RESET: 'roleManageListAction/POST_UPDATE_ROLE_STATUS_RESET',
  RESET_UPDATE_ROLE: 'roleManageListAction/RESET_UPDATE_ROLE',

  DELETE_ROLE_SUCCESS: 'roleManageListAction/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'roleManageListAction/DELETE_ROLE_FAILURE',
  DELETE_ROLE_STATUS_RESET: 'roleManageListAction/DELETE_ROLE_STATUS_RESET',

  ROLE_STATUS_RESET: 'roleManageListAction/ROLE_STATUS_RESET',
}

//* get role list
const getRoleList = () => async dispatch => {
  dispatch(getRoleListBegin());
  try {
    const response = await rolesAtmApi.getFullRoleList();

    const permissionOfRoleList = await Promise.all(
      response.data.map((item) => {
        return rolesAtmApi.getPermissionOfRoleAtm(item.id);
      })
    );

    let roleList = [];

    for (let i = 0; i < response.data.length; i++) {
      roleList.push(
        {
          ...response.data[i],
          permission: [...permissionOfRoleList[i].data]
        }
      );
    }

    dispatch(getRoleListSuccess(roleList));
  } catch (error) {
    dispatch(getRoleListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getRoleListBegin = () => {
  return {
    type: roleManageListActionTypes.GET_ROLE_LIST
  }
}

const getRoleListSuccess = (roleList) => {
  return {
    type: roleManageListActionTypes.GET_ROLE_LIST_SUCCESS,
    payload: {
      roleList: roleList
    },
  }
}

const getRoleListFailure = (error) => {
  return {
    type: roleManageListActionTypes.GET_ROLE_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

//* get permission list
const getPermissionList = () => async dispatch => {
  try {
    const permissionList = await rolesAtmApi.getPermissionList();
    dispatch(getPermissionListSuccess(permissionList.data));
  } catch (error) {
    dispatch(getPermissionListFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getPermissionListSuccess = (permissionList) => {
  return {
    type: roleManageListActionTypes.GET_PERMISSION_LIST_SUCCESS,
    payload: {
      permissionList: permissionList
    },
  }
}

const getPermissionListFailure = (error) => {
  return {
    type: roleManageListActionTypes.GET_PERMISSION_LIST_FAILURE,
    payload: {
      error: error
    },
  }
}

//* create role
const createRole = (roleInfor) => async dispatch => {
  try {
    const response = await rolesAtmApi.postCreateRole(roleInfor);

    const roleCreate = {
      ...response.data,
    }

    await Promise.all(
      roleInfor.permission.map((item) => {
        return rolesAtmApi.addPermissionToRoleAtm(roleCreate.id, item.id);
      })
    );
    dispatch(createRoleSuccess({ ...roleCreate, permission: roleInfor.permission }));

  } catch (error) {
    dispatch(createRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const createRoleSuccess = (roleCreateInfor) => {
  return {
    type: roleManageListActionTypes.CREATE_ROLE_SUCCESS,
    payload: {
      roleCreateInfor: roleCreateInfor,
    },
  }
}

const createRoleFailure = (error) => {
  return {
    type: roleManageListActionTypes.CREATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetCreateRole = () => {
  return {
    type: roleManageListActionTypes.RESET_CREATE_ROLE,
  }
}

//* get update role
const getUpdateRole = (roleId) => async dispatch => {
  try {
    const [roleInfor, permissionOfRole] = await Promise.all([rolesAtmApi.getUpdateRole(roleId), rolesAtmApi.getPermissionOfRoleAtm(roleId)]);
    const roleGetUpdate = {
      ...roleInfor.data,
      permission: [...permissionOfRole.data]
    }
    dispatch(getUpdateRoleSuccess(roleGetUpdate));
  } catch (error) {
    dispatch(getUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const getUpdateRoleSuccess = (roleGetUpdate) => {
  return {
    type: roleManageListActionTypes.GET_UPDATE_ROLE_SUCCESS,
    payload: {
      roleGetUpdate: roleGetUpdate
    },
  }
}

const getUpdateRoleFailure = (error) => {
  return {
    type: roleManageListActionTypes.GET_UPDATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

//* post update role
// const postUpdateRole = (roleInfor) => async dispatch => {
//   try {
//     await rolesAtmApi.postUpdateRole(roleInfor);

//     const listPermissionOfRoleAtmId = { listPermissionOfRoleAtmId: roleInfor.permission.map(item => item.id) };
//     await rolesAtmApi.postUpdatePermissionOfRoleAtm(roleInfor.id, listPermissionOfRoleAtmId);

//     dispatch(postUpdateRoleSuccess());
//   } catch (error) {
//     dispatch(postUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
//   }
// }

//* handle update speed
const postUpdateRole = (roleInfor, oldListPermissionOfRoleId) => async dispatch => {

  const listPermissionCompareId = compareArray(oldListPermissionOfRoleId, roleInfor.permission.map(item => item.id));

  const listPermissionUpdateId = {
    listPermissionOfRoleAtmDeleteId: listPermissionCompareId.listItemDelete,
    listPermissionOfRoleAtmAddId: listPermissionCompareId.listItemAdd
  }

  try {
    await rolesAtmApi.postUpdateRole(roleInfor);
    await rolesAtmApi.postUpdatePermissionOfRoleAtm(roleInfor.id, listPermissionUpdateId);

    dispatch(postUpdateRoleSuccess());
  } catch (error) {
    dispatch(postUpdateRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const postUpdateRoleSuccess = () => {
  return {
    type: roleManageListActionTypes.POST_UPDATE_ROLE_SUCCESS,
  }
}

const postUpdateRoleFailure = (error) => {
  return {
    type: roleManageListActionTypes.POST_UPDATE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

//* reset update role
const resetUpdateRole = () => {
  return {
    type: roleManageListActionTypes.RESET_UPDATE_ROLE,
  }
}

//* delete role
const deleteRole = (roleId) => async dispatch => {
  try {
    const response = await rolesAtmApi.deleteRole(roleId);
    const roleDeleted = {
      ...response.data,
    }
    dispatch(deleteRoleSuccess(roleDeleted.id));
  } catch (error) {
    dispatch(deleteRoleFailure(errorString('ErrorCode: ' + error.response.data.statusCode + '!  ' + JSON.stringify(error.response.data.message))));
  }
}

const deleteRoleSuccess = (roleDeletedId) => {
  return {
    type: roleManageListActionTypes.DELETE_ROLE_SUCCESS,
    payload: {
      roleDeletedId: roleDeletedId
    },
  }
}

const deleteRoleFailure = (error) => {
  return {
    type: roleManageListActionTypes.DELETE_ROLE_FAILURE,
    payload: {
      error: error
    },
  }
}

const resetDeletionStatus = () => {
  return {
    type: roleManageListActionTypes.DELETE_ROLE_STATUS_RESET
  }
}

const resetRoleStatus = () => {
  return {
    type: roleManageListActionTypes.ROLE_STATUS_RESET
  }
}

export default {
  getRoleList,
  getPermissionList,

  createRole,
  resetCreateRole,

  getUpdateRole,
  postUpdateRole,
  resetUpdateRole,

  deleteRole,
  resetDeletionStatus,

  resetRoleStatus,
}
