import { forgotPasswordActionTypes } from '../actions';

const initialState = {};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case forgotPasswordActionTypes.FORGOT_PASSWORD: {
      return {
        ...state,
        status: 'waiting',
      };
    }

    case forgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: 'success'
      };
    }

    case forgotPasswordActionTypes.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        status: 'error'
      }
    }

    case forgotPasswordActionTypes.FORGOT_PASSWORD_RESET: {
      return {}
    }

    default: {
      return state;
    }
  }
};

export default forgotPasswordReducer;
