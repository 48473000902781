import React, { Fragment, useEffect, useState } from 'react';
import { CustomRouterLink, DialogConfirmSignOut } from 'components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, Box } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import { sessionAction } from 'store/actions';
import { Button } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  flexContent: {
    height: '100%',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    marginTop: '10px',
    width: '60px',
    height: '60px'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.info.dark,
  },
  navigation: {
    marginTop: theme.spacing(2),
    paddingLeft: '10px'
  },
  signOut: {
    width: '100%',
    padding: '5px 0px',
  },
  signOutContainer: {
    flex: 'none'
  },
  logoutIcon: {
    marginRight: '8px'
  },
  content: {
    height: '100%',
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const navBar = useSelector(state => state.navBar);

  const [navigation, setNavigation] = useState([]);

  //* Dialog Confirm Sign Out
  const dialogConfirmDefault = { open: false, message: '' };
  const [dialogConfirmValue, setDialogConfirmValue] = useState(dialogConfirmDefault);
  const handleOpenDialogConfirm = () => setDialogConfirmValue({ open: true, message: 'This will end your session' });
  const handleSubmitDialogConfirm = async () => handleLogout();
  const handleCloseDialogConfirm = () => setDialogConfirmValue(dialogConfirmDefault);

  const handleLogout = () => {
    history.push('/auth/login');
    dispatch(sessionAction.logout());
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line
  }, [router.location.pathname]);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line
  }, [router.location.pathname]);

  useEffect(() => {
    const navConfig = navigationConfig(navBar.currentAppId);
    setNavigation(navConfig);
  }, [navBar]);

  if (!session.user) return null;

  const navbarContent = (
    <div className={classes.content}>
      <Box
        display="flex"
        flexDirection="column"
        height={1}
      >
        <Box flexGrow={1}>
          <div className={classes.profile}>
            <Avatar
              alt="Person"
              className={classes.avatar}
              component={CustomRouterLink}
              src={session.user.avatar}
            />
            <Typography
              className={classes.name}
              variant="h4"
            >
              {session.user.firstName} {session.user.lastName}
            </Typography>
            <Typography variant="body2">{session.user.department + ' - ' + session.user.company}</Typography>
          </div>
          
          <Divider className={classes.divider} />
          
          <nav className={classes.navigation}>
            {navigation.map(list => (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages}
                title={list.title}
              />
            ))}
          </nav>         
        </Box>       
        
        <Box className={classes.signOutContainer}>
          <Hidden lgUp >
            <Divider className={classes.divider} />
            <div className={classes.signOut}>
              <Button
                className={classes.logoutButton}
                color="inherit"
                fullWidth
                onClick={handleOpenDialogConfirm}
              >
                <InputIcon className={classes.logoutIcon} />
                  Sign out
              </Button>
            </div>
          </Hidden>
        </Box>        
      </Box>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}          
        </Paper>
      </Hidden>

      <DialogConfirmSignOut
        handleClose={handleCloseDialogConfirm}
        handleConfirm={handleSubmitDialogConfirm}
        message={dialogConfirmValue.message}
        open={dialogConfirmValue.open}
      />
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
